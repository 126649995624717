import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import VariantEditor from './VariantEditor'; // Import the VariantEditor

const LinkEditor = ({ links, setLinks, updateLink, removeLink, saveLink }) => {
  const [expandedIndex, setExpandedIndex] = useState(null); // Manage which link is expanded

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Collapse if already expanded, else expand
  };

  const handleReorder = async (index, direction) => {
    const newLinks = [...links];

    // Swap the position of the link with the one above or below
    if (direction === "up" && index > 0) {
      [newLinks[index - 1], newLinks[index]] = [newLinks[index], newLinks[index - 1]];
    } else if (direction === "down" && index < newLinks.length - 1) {
      [newLinks[index], newLinks[index + 1]] = [newLinks[index + 1], newLinks[index]];
    }

    // Recalculate indices for each link after reordering
    newLinks.forEach((link, idx) => {
      link.index = idx + 1;
    });

    // Update the state with the new order of links
    setLinks(newLinks);

    // Save each link's new order to the backend
    for (let i = 0; i < newLinks.length; i++) {
      await saveLink(i); // Call saveLink for each link to update its index in the backend
    }
  };

  return (
    <div className="p-6">
      {/* Link List */}
      <ul className="space-y-4">
        {links.map((link, index) => (
          <li key={index} className="p-4 bg-gray-100 rounded shadow">
            {/* Collapsed Link Header */}
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                {/* Arrow to expand/collapse */}
                <button
                  onClick={() => toggleExpand(index)}
                  className="text-gray-700"
                >
                  {expandedIndex === index ? (
                    <FontAwesomeIcon icon={faChevronUp} />
                  ) : (
                    <FontAwesomeIcon icon={faChevronDown} />
                  )}
                </button>
                <span className="ml-2">{link.title || "Untitled Link"}</span>
              </div>
              <div className="ml-4 flex items-center">
                {/* Reorder Arrows */}
                <button
                  onClick={() => handleReorder(index, "up")}
                  className="text-blue-500 mr-2"
                  disabled={index === 0}
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </button>
                <button
                  onClick={() => handleReorder(index, "down")}
                  className="text-blue-500"
                  disabled={index === links.length - 1}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
                {/* Delete Button */}
                <button
                  onClick={() => removeLink(index)}
                  className="text-red-500 ml-4"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>

            {/* Expanded Link Editor */}
            {expandedIndex === index && (
              <div className="mt-4">
                <p className="text-sm text-gray-600 mb-2">Link Title:</p>
                <input
                  type="text"
                  value={link.title || ""}
                  onChange={(e) => updateLink(index, "title", e.target.value)}
                  placeholder="Link Title"
                  className="w-full mb-2 p-2 border border-gray-300 rounded"
                />

                <p className="text-sm text-gray-600 mb-2">Link URL:</p>
                <input
                  type="text"
                  value={link.url || ""}
                  onChange={(e) => updateLink(index, "url", e.target.value)}
                  placeholder="Link URL"
                  className="w-full mb-2 p-2 border border-gray-300 rounded"
                />

                <p className="text-sm text-gray-600 mb-2">Image URL:</p>
                <input
                  type="text"
                  value={link.imageUrl || ""}
                  onChange={(e) => updateLink(index, "imageUrl", e.target.value)}
                  placeholder="Image URL"
                  className="w-full mb-2 p-2 border border-gray-300 rounded"
                />

                <p className="text-sm text-gray-600 mb-2">Link Description:</p>
                <textarea
                  value={link.description || ""}
                  onChange={(e) => updateLink(index, "description", e.target.value)}
                  placeholder="Link Description"
                  className="w-full mb-2 p-2 border border-gray-300 rounded"
                />
                <button
                  onClick={() => saveLink(index)}
                  className="mt-2 bg-blue-500 text-white p-2 rounded"
                >
                  Save Link
                </button>
                
                {/* Variant Editor */}
                <VariantEditor linkId={link.id} />
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LinkEditor;
