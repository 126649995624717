import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";

const profitPerConversion = 50;
const initialVariantData = [
  { variant: "Fresh Apple", conversionRate: 4.1, result: "Baseline", visitors: 1000, conversions: 42, revenue: 2100, confidence: 0 },
  { variant: "Red Apple", conversionRate: 4.5, result: "Awaiting significance", visitors: 0, conversions: 0, revenue: 0, confidence: 0 },
  { variant: "Royal Apple", conversionRate: 5.5, result: "Awaiting significance", visitors: 0, conversions: 0, revenue: 0, confidence: 0 },
];

const calculateConfidence = (variantConversions, variantViews, baselineConversions, baselineViews) => {
  const baselineRate = baselineConversions / baselineViews;
  const variantRate = variantConversions / variantViews;
  const pooledRate = (baselineConversions + variantConversions) / (baselineViews + variantViews);
  const standardError = Math.sqrt(pooledRate * (1 - pooledRate) * (1 / baselineViews + 1 / variantViews));
  const zScore = (variantRate - baselineRate) / standardError;
  return Math.min(99, Math.max(0, Math.round((1 - Math.exp(-Math.abs(zScore))) * 100)));
};

const TestDemo = () => {
  const [variantData, setVariantData] = useState(initialVariantData);
  const [winnerDeclared, setWinnerDeclared] = useState(false);
  const [activeVariantIndex, setActiveVariantIndex] = useState(1);
  const [startDemo, setStartDemo] = useState(false);

  useEffect(() => {
    if (!startDemo || winnerDeclared) return;

    const interval = setInterval(() => {
      setVariantData((prevData) => {
        const baseline = prevData[0];
        const activeVariant = prevData[activeVariantIndex];

        const incrementViews = Math.floor(Math.random() * 50) + 250;
        const incrementConversions = Math.floor(incrementViews * (Math.random() * (0.07 - 0.03) + 0.03));
        const newVisitors = activeVariant.visitors + incrementViews;
        const newConversions = activeVariant.conversions + incrementConversions;
        const newRevenue = newConversions * profitPerConversion;
        const newConfidence = calculateConfidence(newConversions, newVisitors, baseline.conversions, baseline.visitors);

        const updatedData = prevData.map((variant, index) =>
          index === activeVariantIndex
            ? { ...variant, visitors: newVisitors, conversions: newConversions, revenue: newRevenue, confidence: newConfidence }
            : variant
        );

        const allVariantsHaveMinViews = updatedData.every((variant) => variant.visitors >= 1000);

        if (allVariantsHaveMinViews) {
          const winner = updatedData.reduce((best, variant) => {
            const bestConversionRate = best.conversions / best.visitors;
            const variantConversionRate = variant.conversions / variant.visitors;
            return variantConversionRate > bestConversionRate ? variant : best;
          });

          setWinnerDeclared(true);

          return updatedData.map((variant) =>
            variant.variant === winner.variant
              ? { ...variant, isBest: true, result: "Statistically Significant Winner" }
              : variant
          );
        }

        return updatedData;
      });

      setActiveVariantIndex((prevIndex) => (prevIndex === variantData.length - 1 ? 1 : prevIndex + 1));
    }, 2500);

    return () => clearInterval(interval);
  }, [startDemo, winnerDeclared, activeVariantIndex, variantData.length]);

  return (
    <section className="flex items-center justify-center pb-6 px-4 md:pb-20">
      <div className="w-full max-w-6xl bg-white rounded-lg shadow-lg p-6 space-y-6">
                {/* Wrapper for Numbered Item */}
                <div className="flex justify-center mb-4">
        </div>
        <div className="px-6">
          <h2 className="text-2xl md:text-3xl font-bold text-black">5. Testing In Action</h2>
          <p className="text-sm md:text-sm text-gray-500 mt-2 py-2">
            Our platform will automatically test and serve different variations on your website to maximize conversions. The top performing variant will be locked in automatically.
          </p>
          <button
            onClick={() => setStartDemo(true)}
            className="bg-blue-600 text-md hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded mt-4"
            disabled={startDemo}
          >
            Start Demo
          </button>
        </div>

        {/* Table Header */}
        <div className="grid grid-cols-6 gap-4 text-center font-semibold text-xs text-gray-400  pb-2">
          <div>Variant</div>
          <div>Views</div>
          <div>Conversions</div>
          <div>%</div>
          <div>CI</div>
          <div>Revenue</div>
        </div>

        {/* Table Rows */}
        {variantData.map(({ variant, visitors, conversions, revenue, confidence, isBest }, index) => {
          const conversionRate = conversions > 0 ? ((conversions / visitors) * 100).toFixed(2) : 0;

          return (
            <div
              key={variant}
              className={`relative grid grid-cols-6 gap-2 text-center items-center py-3 rounded-lg ${isBest ? "border-2 border-yellow-400" : "border border-gray-700"} ${index === activeVariantIndex && !winnerDeclared ? "bg-gray-200" : ""}`}
            >
              <div className="flex justify-center space-x-1">
                {isBest && <FontAwesomeIcon icon={faCrown} className="text-yellow-400" />}
                <span className="font-bold text-xs text-black text-md ml-5">{variant}</span>
              </div>

              <div className="text-gray-600 text-xs">{visitors}</div>
              <div className="text-gray-600 text-xs">{conversions}</div>
              <div className="text-gray-600 text-xs">{conversionRate}%</div>
              <div className="text-gray-600 text-xs">{confidence}</div>
              <div className="text-green-500 font-bold text-xs mr-10">${revenue}</div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default TestDemo;
