// I hope this file is not rewritten by Amplify CLI

export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      name
      bio
      logoURL
      profileViews
      linkBgColor
      profileBgColor
      linkTextColor
      profileTextColor
      profileBioColor
      links {
        items {
          id
          title
          url
          description
          imageUrl
          index
          hasVariants
        }
      }
      phone
      email
      instagramUrl
      tiktokUrl
      facebookUrl
      linkedinUrl
      snapchatUrl
      twitterUrl
      youtubeUrl
      discordUrl
      applePodcastsUrl
      rssUrl
      spotifyUrl
      tiktokUrl
      twitterUrl
      formTitle
      formDescription
      formInputPlaceholder
      formSubmitButtonText
      hasGrid
    }
  }
`;

export const rotateProfileLinkVariant = /* GraphQL */ `
  query GetProfileWithRotation($id: ID!) {
    getProfileWithRotation(id: $id) {
      links {
        items {
          clicks
          views
        }
      }
    }
  }
`;

// customqueries.js
export const incrementLinkClick = /* GraphQL */ `
  query IncrementLinkClick($id: ID!) {
    incrementLinkClick(id: $id) {
      clicks
      views
    }
  }
`;
