import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const InstagramFeed = () => (
  <div className="p-4 bg-gray-50 rounded-lg shadow-md w-64 mx-auto flex flex-col justify-between min-h-[250px]">
    {/* Instagram Title with Icon */}
    <h3 className="text-lg font-semibold mb-1 text-gray-800 flex items-center">
      <FontAwesomeIcon icon={faUser} className="mr-2" />
      Instagram Feed
    </h3>

    {/* Short Description */}
    <p className="text-sm text-gray-600 my-4">
    Experiment with different Instagram posts and test which ones drive the most engagement.
    </p>

    {/* Instagram Feed 2x2 Grid */}
    <div className="bg-white border border-gray-200 rounded-lg shadow-inner p-4">
      <div className="grid grid-cols-2 gap-2">
        <div className="bg-gray-300 w-full h-14 rounded"></div>
        <div className="bg-gray-300 w-full h-14 rounded"></div>
        <div className="bg-gray-300 w-full h-14 rounded"></div>
        <div className="bg-gray-300 w-full h-14 rounded"></div>
      </div>
    </div>
  </div>
);

export default InstagramFeed;
