import React from 'react';
import SongRelease from '../components/SongRelease';
import { faSpotify, faApple, faDeezer } from '@fortawesome/free-brands-svg-icons';

const Flaco = () => {
  // Example album details
  const albumDetails = {
    albumCover: 'https://i.scdn.co/image/ab67616d0000b2736e7183538dd344471fabdc5c',
    albumTitle: 'Täydellinen',
    spotifyLink: 'https://open.spotify.com/track/19mKgvz3lPyUJBXvnXXuvv?go=1',
    albumArtist: 'Blacflaco',
    musicServices: [
      {
        name: 'Spotify',
        icon: faSpotify,
        color: '#1DB954',
        action: 'Kuuntele',
        link: 'https://open.spotify.com/track/19mKgvz3lPyUJBXvnXXuvv?go=1',
      },
      {
        name: 'Apple Music',
        icon: faApple,
        color: '#FA233B',
        action: 'Kuuntele',
        link: 'https://music.apple.com/us/album/t%C3%A4ydellinen-single/1764398405',
      },
    ],
  };

  return (
    <div
      className="relative min-h-screen flex items-center justify-center bg-cover bg-center"
      style={{
        backgroundImage: `url(${albumDetails.albumCover})`,
        backgroundSize: 'cover',
      }}
    >
      {/* Blurred Background Overlay */}
      <div className="absolute inset-0 bg-white/50 backdrop-blur-lg"></div>

      {/* Main Content */}
      <div className="relative z-10">
        <SongRelease
          albumCover={albumDetails.albumCover}
          albumTitle={albumDetails.albumTitle}
          albumArtist={albumDetails.albumArtist}
          musicServices={albumDetails.musicServices}
          spotifyLink={albumDetails.spotifyLink}
        />
      </div>
    </div>
  );
};

export default Flaco;
