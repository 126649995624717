import React, { useState, useRef } from "react";

const AccordionSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  const items = [
    {
      id: 1,
      title: "Dynamic Testing Algorithm",
      content: "Powered by Bayesian inference, our smart algorithm quickly learns what works best and automatically shows visitors your top-performing content."
    },
    {
        id: 2,
        title: "How quickly can I see results?",
        content: "You may start noticing results right away as your site adapts to the best-performing variations—like an increase in orders or bookings. \n We'll send you an email notification once the test results reach statistical significance."
    },
    {
      id: 3,
      title: "What content can I test?",
      content: "Test various elements on your site, including text, images, buttons, and more. For advanced needs, our team can assist with setup."
    },
    {
      id: 4,
      title: "How easy is it to set up?",
      content:
        "Super easy! Just paste a small code snippet into your site. No complex setup or coding required—we handle the heavy lifting for you.",
    },
    {
      id: 5,
      title: "What kind of insights will I get?",
      content:
        "You’ll see exactly which versions of your content are performing best, with easy-to-understand reports showing clicks, conversions, and trends.\n\nPlus, our platform lets you dive deeper to see if certain versions perform better at different times of day or on specific devices.\n\nFor instance, you might find that iPhone users prefer certain wording or design elements. This helps you make smarter, data-driven choices for your site."
    },
    {
      id: 6,
      title: "Can I try it for free?",
      content:
        "Absolutely! We offer a free trial so you can see how it works for yourself. Sign up now to get started.",
    }
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const getHeight = (index) => {
    if (contentRefs.current[index]) {
      return contentRefs.current[index].scrollHeight;
    }
    return 0;
  };

  return (
    <div className="flex justify-center px-4 mt-8 mb-8">
      <div className="w-full max-w-lg bg-gray-800 rounded-lg shadow-lg">
        <h2 className="text-xl font-bold text-center text-white py-6">
          Frequenty Asked Questions
        </h2>

        <div className="border-t border-gray-600 w-4/5 mx-auto mb-4"></div>

        <div id="accordion-open" className="w-full">
          {items.map((item, index) => (
            <div key={item.id}>
              <h2 id={`accordion-open-heading-${item.id}`}>
                <button
                  type="button"
                  className="flex items-center justify-between w-full p-5 text-md font-medium text-gray-300 bg-gray-800 focus:outline-none"
                  aria-expanded={activeIndex === index}
                  aria-controls={`accordion-open-body-${item.id}`}
                  onClick={() => toggleAccordion(index)}
                  style={{ minHeight: "56px" }}
                >
                  <span>{item.title}</span>
                  <svg
                    className={`w-4 h-4 transition-transform duration-200 ${
                      activeIndex === index ? "rotate-180" : ""
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                    aria-hidden="true"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id={`accordion-open-body-${item.id}`}
                ref={(el) => (contentRefs.current[index] = el)}
                className={`overflow-hidden transition-height duration-300 ease-in-out`}
                style={{
                  height:
                    activeIndex === index ? `${getHeight(index)}px` : "0px",
                }}
                aria-labelledby={`accordion-open-heading-${item.id}`}
              >
                <div className="p-5 text-gray-400 bg-gray-800">
                  {item.content.split('\n').map((line, idx) => (
                    <p key={idx} className="mb-2">{line}</p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccordionSection;
