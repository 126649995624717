import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const ContactFormWidget = ({ profile }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="w-full max-w-md mx-auto">
      {/* Button to toggle expand/collapse */}
      <div
        className="block p-4 rounded-lg shadow-md hover:shadow-xl transition-all duration-300 cursor-pointer"
        onClick={toggleExpand}
        style={{
          backgroundColor: profile?.linkBgColor || '#ffffff', // Fallback to white if not provided
          color: profile?.linkTextColor || '#000000', // Fallback to black if not provided
        }}
      >
        <li className="flex items-center justify-between text-center">
          <div className="flex items-center justify-center w-full">
            <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-lg" />
            <span className="text-lg">
              {profile?.formTitle || 'Join our mailing list!'}
            </span>
          </div>
          <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} className="text-lg" />
        </li>
      </div>

      {/* Expandable Content */}
      {expanded && (
        <div className="bg-white border border-gray-200 rounded-lg shadow-inner p-4 mt-2 mx-2">
          <p className="text-gray-600 mb-4 text-center text-sm">
            {profile?.formDescription || 'Sign up for our newsletter to stay updated.'}
          </p>
          <input
            type="email"
            placeholder={profile?.formInputPlaceholder || 'Enter your email'}
            className="p-2 border rounded w-full mb-4 text-center text-sm"
          />
          <button
            className="text-white text-center text-lg py-2 px-4 rounded-full w-full hover:bg-purple-600 transition"
            style={{
              backgroundColor: profile?.linkBgColor || '#8A2BE2', // Fallback to purple if not provided
            }}
          >
            {profile?.formSubmitButtonText || 'Join'}
          </button>
        </div>
      )}
    </div>
  );
};

export default ContactFormWidget;
