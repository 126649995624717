import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import * as customqueries from "../graphql/customqueries";
import SocialMediaIcons from "../components/SocialMediaIcons";
import GridSection from "../components/core/donation/DonationSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ReactGA from "react-ga";
import { useGA } from "../GAContext";
import { Amplify } from "aws-amplify";
import publicConfig from "../amplifyconfiguration_public.json";
import gridItems from "./puheenaihedata.json"

const client = generateClient();

const ExampleLinkSite = () => {
  useEffect(() => {
    Amplify.configure(publicConfig);
  }, []);

  const { name } = useParams();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isGAInitialized } = useGA();

  useEffect(() => {
    if (name) {
      fetchProfileById(name.toLowerCase());

      if (isGAInitialized) {
        const path = window.location.pathname;
        const search = window.location.search;
        const timestamp = new Date().toISOString();

        ReactGA.set({ profileName: name, timestamp });
        ReactGA.pageview(path + search);
      }
    }
  }, [name, isGAInitialized]);

  const fetchProfileById = async (id) => {
    try {
      const profileData = await client.graphql({
        query: customqueries.getProfile,
        variables: { id },
      });

      const profile = profileData.data.getProfile;
      if (profile) {
        const profileViews = profile.profileViews || 0;
        const updatedViews = profileViews + 1;
        setProfile({ ...profile, profileViews: updatedViews });

        rotateView(profile.links.items);
      } else {
        setError("Profile not found.");
      }
    } catch (err) {
      setError("Error fetching profile.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const rotateView = async (links) => {
    const linkWithVariants = links.find((link) => link.hasVariants);

    if (linkWithVariants) {
      try {
        await client.graphql({
          query: customqueries.rotateProfileLinkVariant,
          variables: { id: linkWithVariants.id },
        });
      } catch (err) {
        console.error("Error rotating link variant:", err);
      }
    }
  };

  const handleLinkClick = async (link) => {
    try {
      if (isGAInitialized) {
        ReactGA.event({
          category: "Link Click",
          action: "Click",
          label: link.title,
        });
      }

      await client.graphql({
        query: customqueries.incrementLinkClick,
        variables: { id: link.id },
      });
    } catch (err) {
      console.error("Error incrementing link clicks:", err);
    }
  };

  return (
    <div
      className="w-full max-w-lg text-center mb-6 p-4 sm:p-6"
      style={{ backgroundColor: profile?.profileBgColor || "#ffffff" }}
    >
      {/* Header / Bio Section */}
      <div className="w-full max-w-lg text-center mb-6">
        {loading ? (
          <SkeletonWrapper className="space-y-4">
            <Skeleton circle height={50} width={50} className="mx-auto mb-4" />
            <Skeleton height={25} width="60%" className="mx-auto" />
            <Skeleton height={40} width="80%" className="mx-auto" />
          </SkeletonWrapper>
        ) : error ? (
          <p className="text-red-500">Error loading profile: {error}</p>
        ) : (
          <HeaderSection profile={profile} />
        )}
      </div>

      {/* Title for links section */}
      <div className="w-full max-w-lg">
        <h2 className="text-md font-semibold text-center text-gray-400">
          Linkit
        </h2>
      </div>

      {/* Links Section */}
      <div className="w-full max-w-lg mt-5">
        {loading ? (
          <SkeletonWrapper>
            {[1, 2, 3].map((item) => (
              <Skeleton key={item} height={60} className="mx-auto my-2" />
            ))}
          </SkeletonWrapper>
        ) : error ? (
          <p className="text-red-500">Error loading links: {error}</p>
        ) : (
          <LinksSection profile={profile} onLinkClick={handleLinkClick} />
        )}
      </div>

      {/* Conditionally render GridSection if profile has grid */}
      {profile?.hasGrid && (
        <div className="w-full max-w-lg mt-5">
          <div className="w-full max-w-lg mb-5">
            <h2 className="text-md font-semibold text-center text-gray-400">
              Jaksot
            </h2>
          </div>
          <GridSection items={gridItems} />
        </div>
      )}
    </div>
  );
};

// Skeleton Wrapper
const SkeletonWrapper = ({ children, className }) => (
  <div className={className}>{children}</div>
);

const HeaderSection = ({ profile }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div>
      <div className="mx-auto mb-4 rounded-full" style={{ width: 50, height: 50 }}>
        {profile?.logoURL && (
          <img
            src={profile.logoURL}
            height={50}
            width={50}
            alt="Profile"
            className={`rounded-full object-cover transition-opacity duration-300 ${
              imageLoaded ? "opacity-100" : "opacity-0"
            }`}
            onLoad={() => setImageLoaded(true)}
            style={{ opacity: imageLoaded ? 1 : 0 }}
          />
        )}
      </div>

      <h3
        className="capitalize text-xl font-bold"
        style={{ color: profile?.profileTextColor || "#000000" }}
      >
        {profile?.name}
      </h3>

      <p
        className="text-md mt-2 line-clamp-2"
        style={{ color: profile?.profileBioColor || "#000000" }}
      >
        {profile?.bio}
      </p>

      <div className="mt-4">
        <SocialMediaIcons profile={profile} />
      </div>
    </div>
  );
};

// Links Section
const LinksSection = ({ profile, onLinkClick }) => {
  const [formExpanded, setFormExpanded] = useState(false);

  return (
    <ul className="space-y-4">
      {profile?.links?.items?.length > 0 ? (
        profile.links.items
          .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
          .map((link, index) => (
            <a
              key={index}
              href={link.url}
              className="block p-4 rounded-lg shadow-md hover:shadow-xl transition-all duration-300 mx-2 relative"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => onLinkClick(link)}
              style={{
                backgroundColor: profile.linkBgColor || "#ffffff",
              }}
            >
              <li className="flex items-center justify-center relative">
                {link.imageUrl && (
                  <img
                    src={link.imageUrl}
                    alt={link.title}
                    className="w-5 h-5 object-cover rounded-lg absolute left-0 mr-2"
                  />
                )}
                <div
                  className="flex flex-col justify-center text-center w-full"
                  style={{ color: profile.linkTextColor || "#000000" }}
                >
                  <h4 className="text-md font-semibold">{link.title}</h4>
                  {link.description && (
                    <p className="text-gray-600">{link.description}</p>
                  )}
                </div>
              </li>
            </a>
          ))
      ) : (
        <p>No links available for this profile.</p>
      )}
      {profile?.formTitle && (
        <li
          className="block p-4 rounded-lg shadow-md hover:shadow-xl transition-all duration-300 mx-2 cursor-pointer relative"
          style={{
            backgroundColor: profile.linkBgColor || "#ffffff",
            color: profile.linkTextColor || "#000000",
          }}
        >
          <div
            className="flex items-center justify-between text-center relative"
            onClick={() => setFormExpanded(!formExpanded)}
          >
            <h4 className="text-lg text-center w-full">{profile.formTitle}</h4>
            <FontAwesomeIcon
              icon={formExpanded ? faChevronUp : faChevronDown}
              className="text-lg absolute right-4 top-1/2 transform -translate-y-1/2"
            />
          </div>

          {formExpanded && (
            <div
              className="bg-white absolute left-0 right-0 p-4 rounded-b-lg mt-2 -mb-4 shadow-md"
              onClick={(e) => e.stopPropagation()}
            >
              <p className="text-gray-600 my-2 text-sm">
                {profile?.formDescription || "Get -20% discount on your first purchase!"}
              </p>
              <div className="flex items-center my-4">
                <input
                  type="email"
                  placeholder={profile?.formInputPlaceholder || "Email address"}
                  className="p-2 border rounded w-full text-sm"
                />
              </div>
              <div className="flex items-center justify-end mt-4 space-x-4">
                <div className="flex items-center space-x-2">
                  <p className="text-xs text-gray-400">I agree to the privacy policy</p>
                  <input type="checkbox" />
                </div>
                <button
                  className="text-white text-sm py-2 px-6 rounded transition"
                  style={{
                    backgroundColor: profile?.linkBgColor || "#8A2BE2",
                  }}
                >
                  {profile?.formSubmitButtonText || "Join"}
                </button>
              </div>
            </div>
          )}
        </li>
      )}
    </ul>
  );
};

export default ExampleLinkSite;
