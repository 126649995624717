import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic } from "@fortawesome/free-solid-svg-icons";

const LatestReleaseWidget = () => {
  return (
    <div className="p-4 bg-gray-50 rounded-lg shadow-md w-64 mx-auto flex flex-col justify-between min-h-[235px]">
      {/* Widget Title with FontAwesome Icon */}
      <h3 className="text-lg font-semibold mb-1 text-gray-800">
        <FontAwesomeIcon icon={faMusic} className="mr-2" />
        Latest Release
      </h3>

      {/* Short Description */}
      <p className="text-sm text-gray-600 my-4">Direct your listeners to the most recent album or episode release.</p>

      {/* Platform Selection */}
      <div className="bg-white border border-gray-200 rounded-lg shadow-inner relative p-4 text-center">
        <button className="bg-red-500 text-white py-2 px-4 rounded-full shadow-lg mb-2 w-full hover:bg-red-600 transition">
          Play on YouTube
        </button>
        <button className="bg-green-500 text-white py-2 px-4 rounded-full shadow-lg w-full hover:bg-green-600 transition">
          Play on Spotify
        </button>
      </div>
    </div>
  );
};

export default LatestReleaseWidget;
