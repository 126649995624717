import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt, faBarcode, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import FinnishBankUtils from 'finnish-bank-utils';

const icons = {
  MobilePay: faMobileAlt,
  Viivakoodi: faBarcode,
  Verkkomaksu: faCreditCard,
};

const DonateButton = ({ 
  primaryColor, 
  selectedAmount, 
  paymentMethod, 
  currency, 
  isMonthly, 
  iban, 
  mobilepayLink 
}) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    if (paymentMethod === "MobilePay") {
      window.open(mobilepayLink, "_blank");
    } else if (paymentMethod === "Viivakoodi") {
      const sum = parseFloat(selectedAmount).toFixed(1);
      const reference = "55958 22432 94671";
      const date = new Date();
      const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      const barcode = FinnishBankUtils.formatFinnishVirtualBarCode({
        iban: iban,
        sum: parseFloat(sum),
        reference: reference,
        date: formattedDate,
      });

      if (barcode) {
        navigator.clipboard.writeText(barcode)
          .then(() => setCopied(true))
          .catch(() => console.error("Failed to copy the barcode."));
      } else {
        console.error("Failed to generate the barcode.");
      }
    } else {
      console.log(`Donating ${selectedAmount} ${currency} via ${paymentMethod}`);
    }
  };

  return (
    <div className="flex flex-col items-center space-y-2">
      <button
        onClick={handleClick}
        className="flex items-center justify-center w-full max-w-xs px-4 py-2 text-lg font-semibold text-white rounded-lg hover:opacity-90"
        style={{ backgroundColor: primaryColor }}
      >
        <FontAwesomeIcon icon={icons[paymentMethod]} className="mr-2 text-2xl" />
        {paymentMethod}
      </button>
      {copied && (
        <div className="text-sm text-green-500">
          Barcode copied to clipboard!
        </div>
      )}
    </div>
  );
};

export default DonateButton;
