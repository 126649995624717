import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpotify,
  faApple,
  faDeezer,
  faTidal,
  faQobuz,
} from "@fortawesome/free-brands-svg-icons";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

const SongRelease = ({
  albumCover,
  albumTitle,
  albumArtist,
  musicServices,
  spotifyLink,
}) => {
  return (
    <div className="flex flex-col items-center min-h-screen p-6">
      {/* Album Cover Section */}
      <a
        href={spotifyLink}
        target="_blank"
        rel="noopener noreferrer"
        className="relative"
      >
        <img
          className="rounded-md shadow-lg w-40  object-cover"
          src={albumCover}
          alt={`${albumTitle} - ${albumArtist}`}
        />
        <div className="absolute inset-0 flex justify-center items-center">
          <button className="bg-white/10 backdrop-blur-sm w-16 h-16 rounded-full shadow-lg flex items-center justify-center border border-white/20">
            <FontAwesomeIcon icon={faPlay} size="2x" className="text-white" />
          </button>
        </div>
      </a>

      {/* Album Title and Artist */}
      <div className="text-center mt-4">
        <h1 className="text-xl font-bold">{albumTitle}</h1>
        <p className="text text-gray-600 mt-1">{albumArtist}</p>
      </div>

      {/* Music Services Section */}
      <div className="bg-white rounded-lg shadow-lg mt-6 max-w-xl w-full mx-5">
        <nav className="space-y-2">
          {musicServices.map((service, index) => (
            <a
              href={service.link}
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              className="block bg-white rounded-md shadow p-4 hover:bg-gray-50"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <FontAwesomeIcon
                    icon={service.icon}
                    className="text-4xl"
                    style={{ color: service.color }}
                  />
                  <span className="text-md font-medium">{service.name}</span>
                </div>
                <button className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md">
                  {service.action}
                </button>
              </div>
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default SongRelease;
