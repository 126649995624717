// OverlayContent.js
import React from "react";

const OverlayContent = ({ charityData }) => (
  <div className="absolute inset-0 bg-gradient-to-r from-black/70 via-black/60 to-transparent p-6 flex flex-col justify-center lg:justify-start lg:p-10 text-white">
    <div className="flex items-center mb-6 space-x-4">
      <img
        src={charityData.profilePictureUrl}
        alt="Profile"
        className="w-12 h-12 rounded-full border-2 border-white"
      />
      <h2 className="text-xl lg:text-2xl font-bold">
        {charityData.name}
      </h2>
    </div>
    <p className="text-lg lg:text-xl mb-8 max-w-md">
      {charityData.description}
    </p>
  </div>
);

export default OverlayContent;
