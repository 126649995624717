import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple } from "@fortawesome/free-brands-svg-icons"; // Use Apple's logo icon

const AppInstallWidget = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-lg w-64 mx-auto flex flex-col justify-between min-h-[200px]">
      {/* Widget Title */}
      <h3 className="text-lg font-semibold mb-3 text-gray-900 flex items-center">
        <FontAwesomeIcon icon={faApple} className="mr-2 text-black text-2xl" />
        App installation widget
      </h3>

      {/* Short Description */}
      <p className="text-sm text-gray-600 mb-4">
        Download our app from the App Store for the best experience.
      </p>

      {/* Apple Styled Install Button */}
      <div className="flex justify-center">
        <a
          href="https://apps.apple.com"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center justify-center px-4 py-2 w-full bg-black text-white rounded-full hover:bg-gray-900 transition duration-300 shadow-lg"
        >
          <FontAwesomeIcon icon={faApple} className="mr-2 text-xl" />
          <div className="text-left">
            <p className="text-xs">Download on the</p>
            <p className="font-semibold text-base">App Store</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default AppInstallWidget;
