import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

// Countdown logic for a fixed release time
const calculateTimeLeft = () => {
  const difference = +new Date("2024-12-31") - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

const CountdownWidget = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="p-4 bg-gray-50 rounded-lg shadow-md w-64 mx-auto flex flex-col justify-between min-h-[235px]">
      {/* Widget Title with FontAwesome Icon */}
      <h3 className="text-lg font-semibold mb-1 text-gray-800">
        <FontAwesomeIcon icon={faClock} className="mr-2" />
        Countdown to Release
      </h3>

      {/* Short Description */}
      <p className="text-sm text-gray-600 my-4">Keep up the excitement with countdown widget.</p>

      {/* Countdown Timer */}
      <div className="bg-white border border-gray-200 rounded-lg shadow-inner relative p-4 text-center">
        {timeLeft.hours || timeLeft.minutes || timeLeft.seconds ? (
          <div>
            <p className="text-sm text-gray-600 mb-2">New collection dropping in</p>

            <p className="text-lg font-bold">
              {String(timeLeft.hours).padStart(2, '0')}:
              {String(timeLeft.minutes).padStart(2, '0')}:
              {String(timeLeft.seconds).padStart(2, '0')}
            </p>
          </div>
        ) : (
          <p className="text-lg font-bold">Released!</p>
        )}
      </div>
    </div>
  );
};

export default CountdownWidget;
