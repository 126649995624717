// GAContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import ReactGA from "react-ga";

const GAContext = createContext();
const TRACKING_ID = "G-B897QRRJYY"; // Replace with your actual GA Measurement ID

export const GAProvider = ({ children }) => {
  const [isGAInitialized, setIsGAInitialized] = useState(false);

  // Capture full user-agent string
  const userAgentString = navigator.userAgent;

  // This function initializes Google Analytics after consent is granted
  const grantConsent = () => {
    console.log("User consent granted. Initializing Google Analytics.");

    if (!isGAInitialized) {
      // Initialize GA with the tracking ID
      ReactGA.initialize(TRACKING_ID, { gaOptions: { storage: 'none' } });
      setIsGAInitialized(true);
    }

    // Update Google Analytics consent mode to "granted"
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag('consent', 'update', {
        'ad_storage': 'granted',
        'analytics_storage': 'granted'
      });

      // Send user-agent string as a custom dimension (set dimension index accordingly)
      ReactGA.set({ 'dimension2': userAgentString });
      console.log("Tracking user-agent string:", userAgentString);
    }
  };

  return (
    <GAContext.Provider value={{ isGAInitialized, grantConsent }}>
      {children}
    </GAContext.Provider>
  );
};

// Custom hook to access the GA context
export const useGA = () => useContext(GAContext);
