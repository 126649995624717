import React from "react";

const CallToActionButton = ({ onClick, buttonText, primaryColor, className = "", style = {} }) => (
  <div className="px-6">
    <button
      onClick={onClick}
      className={`w-full p-2 text-xl font-semibold flex items-center justify-center shadow-inner transition-opacity duration-300 hover:opacity-90 ${className}`}
      style={{
        backgroundColor: primaryColor,
        borderColor: primaryColor,
        color: "white",
        borderWidth: "2px",
        ...style
      }}
    >
      {buttonText}
    </button>
  </div>
);

export default CallToActionButton;
