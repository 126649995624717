import React, { useState } from "react";
import AmountSelection from "./AmountSelection";
import DonateButton from "./DonateButton";

const DonationForm = ({ charityData, isMonthly, setIsMonthly, selectedAmount, setSelectedAmount, iban }) => {
  const donationOptions = isMonthly
    ? charityData.monthlyDonationOptions
    : charityData.oneTimeDonationOptions;

  const primaryColor = charityData.primaryColor;

  // Track whether the user is setting a custom amount
  const [isCustomAmount, setIsCustomAmount] = useState(false);

  const handleContinueClick = () => {
    setIsCustomAmount(false); // Confirm the custom amount
  };

  return (
    <div className="px-4">
      {/* Title with scroll-margin-top */}
      <h2
        className="text-xl font-bold text-white mb-10 scroll-mt-24"
        id="donationTitle" // Adding an ID for better targeting if needed
      >
        {charityData.name}
      </h2>

      {/* Monthly / One-Time Toggle */}
      <div className="w-full mb-4">
        <button
          onClick={() => setIsMonthly(!isMonthly)}
          className="w-full bg-white text-lg border-2 font-semibold flex items-center shadow-inner"
          style={{ borderColor: primaryColor }}
        >
          <div
            className={`w-1/2 text-center py-2 transition-all duration-300 ${
              isMonthly ? "text-white" : "text-gray-700"
            }`}
            style={
              isMonthly
                ? { backgroundColor: primaryColor, color: "white" }
                : { color: primaryColor }
            }
          >
            {charityData.monthlyLabel}
          </div>
          <div
            className={`w-1/2 text-center py-2 transition-all duration-300 ${
              !isMonthly ? "text-white" : "text-gray-700"
            }`}
            style={
              !isMonthly
                ? { backgroundColor: primaryColor, color: "white" }
                : { color: primaryColor }
            }
          >
            {charityData.oneTimeLabel}
          </div>
        </button>
      </div>

      {/* Amount Selection */}
      <AmountSelection
        donationOptions={donationOptions}
        selectedAmount={selectedAmount}
        setSelectedAmount={setSelectedAmount}
        primaryColor={primaryColor}
        placeholderAmount={charityData.placeholderAmount}
        isCustomAmount={isCustomAmount}
        setIsCustomAmount={setIsCustomAmount}
      />

      {/* Confirm Custom Amount Button */}
      {isCustomAmount && (
        <button
          onClick={handleContinueClick}
          className="mt-6 w-full p-4 text-lg font-semibold hover:opacity-90 bg-gray-700 text-white rounded"
        >
          Confirm Amount
        </button>
      )}

      {/* Payment Options */}
      <div className="mt-6">
        <h3 className="text-white text-lg font-semibold mb-2">Choose a payment method:</h3>
        <div className="flex flex-wrap gap-4">
          {charityData.paymentMethods.map((method, index) => (
            <DonateButton
              key={index}
              primaryColor={primaryColor}
              selectedAmount={selectedAmount || donationOptions[0].amount}
              paymentMethod={method}
              currency={donationOptions[0].currency}
              isMonthly={isMonthly}
              iban={charityData.iban}
              mobilepayLink={charityData.mobilepayLink}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DonationForm;
