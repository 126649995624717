import React, { useState } from "react";
import DesktopLayout from "../components/core/donation/DesktopLayout";
import MobileLayout from "../components/core/donation/MobileLayout";

const Test = () => {
  const charityData = {
    name: "Join the Helping Hands Charity",
    description:
      "Your support goes a long way to help our cause and mission to save children. We are a non-profit organization that relies on donations to provide medical treatment to children in need.",
    primaryColor: "#728567",
    profilePictureUrl:
      "https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-512.png",
    backgroundImageUrl:
      "https://images.pexels.com/photos/417070/pexels-photo-417070.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    placeholderAmount: 2,
    paymentMethods: ["Viivakoodi", "MobilePay"],
    iban: "FI5810171000000122",
    mobilepayLink: "mobilepayfi://send?phone=77772&comment=Lahjoitus",
    monthlyLabel: "Kuukausilahjoitus",
    oneTimeLabel: "Kertalahjoitus",
    monthlyDonationOptions: [
      { amount: 10, currency: "€" },
      { amount: 30, currency: "€" },
    ],
    oneTimeDonationOptions: [
      { amount: 50, currency: "€" },
      { amount: 100, currency: "€" },
    ],
    buttonText: "Become a Member",
    questions: [
      {
        id: "name",
        label: "Full Name",
        type: "text",
        placeholder: "Enter your name",
      },
    ],
  };

  const [isMonthly, setIsMonthly] = useState(true);
  const [selectedAmount, setSelectedAmount] = useState(null);

  // Define reusable spacing classes
  const spacingClasses = "px-6 py-8";

  return (
    <>
      {/* Desktop Layout */}
      <div className="hidden lg:flex max-w-4xl mx-auto shadow-lg rounded-lg overflow-hidden bg-black">
        <DesktopLayout
          charityData={charityData}
          isMonthly={isMonthly}
          setIsMonthly={setIsMonthly}
          selectedAmount={selectedAmount}
          setSelectedAmount={setSelectedAmount}
          spacingClasses={spacingClasses}
        />
      </div>

      {/* Mobile Layout */}
      <div className="lg:hidden w-full min-h-screen bg-black">
        <MobileLayout
          charityData={charityData}
          isMonthly={isMonthly}
          setIsMonthly={setIsMonthly}
          selectedAmount={selectedAmount}
          setSelectedAmount={setSelectedAmount}
          spacingClasses={spacingClasses}
        />
      </div>
    </>
  );
};

export default Test;
