import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faLinkedin,
  faTiktok,
  faSnapchat,
  faYoutube,
  faSpotify,
  faApple,
  faDiscord,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

const SocialMediaIcons = ({ profile }) => {
  const icons = [
    { platform: "Instagram", url: profile.instagramUrl, icon: faInstagram },
    { platform: "Facebook", url: profile.facebookUrl, icon: faFacebook },
    { platform: "LinkedIn", url: profile.linkedinUrl, icon: faLinkedin },
    { platform: "TikTok", url: profile.tiktokUrl, icon: faTiktok },
    { platform: "Snapchat", url: profile.snapchatUrl, icon: faSnapchat },
    { platform: "YouTube", url: profile.youtubeUrl, icon: faYoutube },
    { platform: "Spotify", url: profile.spotifyUrl, icon: faSpotify },
    {
      platform: "Apple Podcasts",
      url: profile.applePodcastsUrl,
      icon: faApple,
    },
    { platform: "Discord", url: profile.discordUrl, icon: faDiscord },
    { platform: "Twitter", url: profile.twitterUrl, icon: faTwitter },
    { platform: "RSS", url: profile.rssUrl, icon: faEnvelope },
    {
      platform: "Phone",
      url: profile.phone ? `tel:${profile.phone}` : null,
      icon: faPhone,
    },
    {
      platform: "Email",
      url: profile.email ? `mailto:${profile.email}` : null,
      icon: faEnvelope,
    },
  ];

  return (
    <div className="mt-3 max-w-lg mx-auto flex flex-wrap justify-center gap-4">
      {icons.map(
        ({ platform, url, icon }) =>
          url && (
            <a
              key={platform}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-600 hover:text-blue-600 transition-colors"
            >
              <FontAwesomeIcon
                icon={icon}
                className="w-4 h-4 bg-gray-200 p-3 rounded-full"
              />
            </a>
          )
      )}
    </div>
  );
};

export default SocialMediaIcons;
