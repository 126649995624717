/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      name
      bio
      logoURL
      profileBgColor
      profileTextColor
      profileBioColor
      links {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      phone
      email
      instagramUrl
      tiktokUrl
      facebookUrl
      linkedinUrl
      snapchatUrl
      youtubeUrl
      spotifyUrl
      applePodcastsUrl
      discordUrl
      twitterUrl
      rssUrl
      createdAt
      updatedAt
      linkBgColor
      linkBorderColor
      linkTextColor
      formTitle
      formDescription
      formInputPlaceholder
      formSubmitButtonText
      profileViews
      hasGrid
      owner
      __typename
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $id: ID
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProfiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        bio
        logoURL
        profileBgColor
        profileTextColor
        profileBioColor
        phone
        email
        instagramUrl
        tiktokUrl
        facebookUrl
        linkedinUrl
        snapchatUrl
        youtubeUrl
        spotifyUrl
        applePodcastsUrl
        discordUrl
        twitterUrl
        rssUrl
        createdAt
        updatedAt
        linkBgColor
        linkBorderColor
        linkTextColor
        formTitle
        formDescription
        formInputPlaceholder
        formSubmitButtonText
        profileViews
        hasGrid
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const profileByName = /* GraphQL */ `
  query ProfileByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profileByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        bio
        logoURL
        profileBgColor
        profileTextColor
        profileBioColor
        phone
        email
        instagramUrl
        tiktokUrl
        facebookUrl
        linkedinUrl
        snapchatUrl
        youtubeUrl
        spotifyUrl
        applePodcastsUrl
        discordUrl
        twitterUrl
        rssUrl
        createdAt
        updatedAt
        linkBgColor
        linkBorderColor
        linkTextColor
        formTitle
        formDescription
        formInputPlaceholder
        formSubmitButtonText
        profileViews
        hasGrid
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      profileID
      message
      type
      createdAt
      updatedAt
      index
      owner
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $id: ID
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMessages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        profileID
        message
        type
        createdAt
        updatedAt
        index
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByProfileIDAndCreatedAt = /* GraphQL */ `
  query MessagesByProfileIDAndCreatedAt(
    $profileID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByProfileIDAndCreatedAt(
      profileID: $profileID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileID
        message
        type
        createdAt
        updatedAt
        index
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLink = /* GraphQL */ `
  query GetLink($id: ID!) {
    getLink(id: $id) {
      id
      profileID
      url
      imageUrl
      title
      description
      variants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      index
      totalViews
      totalClicks
      views
      clicks
      hasVariants
      owner
      __typename
    }
  }
`;
export const listLinks = /* GraphQL */ `
  query ListLinks(
    $id: ID
    $filter: ModelLinkFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLinks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        profileID
        url
        imageUrl
        title
        description
        createdAt
        updatedAt
        index
        totalViews
        totalClicks
        views
        clicks
        hasVariants
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const linksByProfileIDAndCreatedAt = /* GraphQL */ `
  query LinksByProfileIDAndCreatedAt(
    $profileID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linksByProfileIDAndCreatedAt(
      profileID: $profileID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        profileID
        url
        imageUrl
        title
        description
        createdAt
        updatedAt
        index
        totalViews
        totalClicks
        views
        clicks
        hasVariants
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLinkVariant = /* GraphQL */ `
  query GetLinkVariant($id: ID!) {
    getLinkVariant(id: $id) {
      id
      linkID
      url
      imageUrl
      title
      description
      createdAt
      updatedAt
      index
      totalViews
      totalClicks
      views
      clicks
      conversions
      hoverEvents
      timeSpent
      bounceRate
      owner
      __typename
    }
  }
`;
export const listLinkVariants = /* GraphQL */ `
  query ListLinkVariants(
    $id: ID
    $filter: ModelLinkVariantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLinkVariants(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        linkID
        url
        imageUrl
        title
        description
        createdAt
        updatedAt
        index
        totalViews
        totalClicks
        views
        clicks
        conversions
        hoverEvents
        timeSpent
        bounceRate
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const linkVariantsByLinkIDAndCreatedAt = /* GraphQL */ `
  query LinkVariantsByLinkIDAndCreatedAt(
    $linkID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLinkVariantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    linkVariantsByLinkIDAndCreatedAt(
      linkID: $linkID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        linkID
        url
        imageUrl
        title
        description
        createdAt
        updatedAt
        index
        totalViews
        totalClicks
        views
        clicks
        conversions
        hoverEvents
        timeSpent
        bounceRate
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProfileWithRotation = /* GraphQL */ `
  query GetProfileWithRotation($id: ID!) {
    getProfileWithRotation(id: $id) {
      id
      name
      bio
      logoURL
      profileBgColor
      profileTextColor
      profileBioColor
      links {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      phone
      email
      instagramUrl
      tiktokUrl
      facebookUrl
      linkedinUrl
      snapchatUrl
      youtubeUrl
      spotifyUrl
      applePodcastsUrl
      discordUrl
      twitterUrl
      rssUrl
      createdAt
      updatedAt
      linkBgColor
      linkBorderColor
      linkTextColor
      formTitle
      formDescription
      formInputPlaceholder
      formSubmitButtonText
      profileViews
      hasGrid
      owner
      __typename
    }
  }
`;
export const incrementLinkClick = /* GraphQL */ `
  query IncrementLinkClick($id: ID!) {
    incrementLinkClick(id: $id) {
      id
      profileID
      url
      imageUrl
      title
      description
      variants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      index
      totalViews
      totalClicks
      views
      clicks
      hasVariants
      owner
      __typename
    }
  }
`;
