import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";

const InsightCarousel = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  // Array of text variations that will cycle through
  const textVariations = [
    "Best-selling Product",
    "Latest Release",
    "Customer Favorite",
    "New Arrival",
    "Limited Edition"
  ];

  // Effect to change text every 4 seconds for a smoother cycle
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % textVariations.length);
    }, 4000); // Change every 4 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, [textVariations.length]);

  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-lg w-72 mx-auto flex flex-col justify-between min-h-[280px]">
      {/* Insight Carousel Title with Icon */}
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold text-gray-800">
          <FontAwesomeIcon icon={faChartBar} className="mr-2" />
          Insight Carousel
        </h3>
      </div>

      {/* Short Description */}
      <p className="text-sm text-gray-600 mb-6">
        Automatically tests and optimizes product displays to find the best visuals and layouts.
      </p>

      {/* Animated Carousel Element */}
      <div className="bg-white border border-gray-200 rounded-lg shadow-inner p-6 h-28 flex items-center justify-center">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentTextIndex}
            initial={{ opacity: 0, x: 30 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -30 }}
            transition={{ duration: 0.6, ease: [0.6, -0.05, 0.01, 0.99] }}
            className="text-lg font-semibold text-gray-800 text-center"
          >
            {textVariations[currentTextIndex]}
          </motion.div>
        </AnimatePresence>
      </div>

      {/* Bottom Visual Guide for Carousel */}
      <div className="flex justify-center space-x-2 mt-4">
        {textVariations.map((_, index) => (
          <span
            key={index}
            className={`block w-2 h-2 rounded-full transition-all ${
              index === currentTextIndex ? "bg-blue-500" : "bg-gray-300"
            }`}
          />
        ))}
      </div>
    </div>
  );
};

export default InsightCarousel;
