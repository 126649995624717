import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { faTrash, faEdit, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

const OnboardingSection = () => {
  const [selectedElement, setSelectedElement] = useState("title");
  const [url, setUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState(null);
  const [error, setError] = useState(null);
  const [variations, setVariations] = useState([
    { id: 1, name: "Red Apple" },
    { id: 2, name: "Royal Gala Apple" },
    { id: 3, name: "Delicious Apple" },
  ]);

  // Function to handle generating a new variation
  const handleGenerateVariation = () => {
    const newVariationId = variations.length + 1;
    setVariations([
      ...variations,
      { id: newVariationId, name: `New Apple Variation ${newVariationId}` },
    ]);
  };

  // Function to handle selection of individual elements within the visual
  const handleSelect = (element) => {
    setSelectedElement(element);
  };

  const fetchLogo = async () => {
    try {
      const response = await fetch(`https://logodog.dev/api/get?url=${url}`, {
        headers: {
          Authorization: "Bearer lAKZkEGo3LLBv5wFsCrnsyGP61qawqbyw53F4cjn7cf8c9d7",
        },
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
  
      if (data.success) {
        console.log("Logo URL:", data.logo_url); // Log the image URL to the console
        setLogoUrl(data.logo_url);
      } else {
        setError("Failed to fetch logo.");
      }
    } catch (error) {
      setError("Error fetching logo.");
      console.error("Fetch error:", error); // Log any errors that occur during fetch
    }
  };
  
  


  // Handle input change
  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setLogoUrl(null); // Reset logoUrl when URL changes
    setError(null); // Reset error when URL changes
  };

  // Handle fetching logo on button click
  const handleFetchLogo = () => {
    if (url) fetchLogo();
  };

  // Step data array
  const steps = [
    {
      number: "1",
      title: "Sign up",
      description:
        "Sign up and provide your website",
      visual: (
        <div className="flex items-center justify-center mt-6 mb-4">


          {/* Input and Button */}
          <div className="relative w-80 flex items-center">
            <input
              type="text"
              value={url}
              onChange={handleUrlChange}
              placeholder="https://example.com"
              className="w-full p-2 text-gray-800 text-sm rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 pr-12"
            />
            <button
              onClick={handleUrlChange}
              className="absolute right-2 top-1/2 p-1 transform -translate-y-1/2 text-blue-500 hover:text-gray-400 focus:outline-none"
            >
              <FontAwesomeIcon icon={faArrowCircleRight} className="h-5 w-5" />
            </button>
          </div>
        </div>
      ),
    },

    {
      number: "2",
      title: "Select Test Target",
      description: "You can select any element for optimization",
      visual: (
        <div className="w-full bg-gray-100 rounded-lg shadow-lg p-4 mt-10 text-black mb-4 flex items-center gap-4 transition-transform duration-300">
          {/* Product Image */}
          <div
            onClick={() => handleSelect("image")}
            className="relative text-5xl text-center cursor-pointer"
          >
            {selectedElement === "image" && (
              <span className="absolute top-[-16px] left-1/2 transform -translate-x-1/2 text-xs font-semibold text-green-300 bg-green-800 p-1 rounded-md">
                Selected
              </span>
            )}
            <div
              className={`rounded-md ${
                selectedElement === "image"
                  ? "outline outline-2 outline-green-500"
                  : ""
              }`}
            >
              🍎
            </div>
          </div>
    
          {/* Product Details */}
          <div className="flex-1">
            <div className="relative inline-block cursor-pointer">
              {selectedElement === "title" && (
                <span className="absolute top-[-16px] left-1/2 transform -translate-x-1/2 text-xs font-semibold text-green-300 bg-green-800 p-1 rounded-md">
                  Selected
                </span>
              )}
              <span
                onClick={() => handleSelect("title")}
                className={`text-xl text-black font-bold mb-2 rounded-md shadow-md ${
                  selectedElement === "title"
                    ? "outline outline-2 outline-green-500"
                    : ""
                }`}
              >
                Fresh Apple
              </span>
            </div>
            <p
              onClick={() => handleSelect("description")}
              className="relative text-gray-700 text-sm cursor-pointer"
            >
              {selectedElement === "description" && (
                <span className="absolute top-[-16px] left-1/2 transform -translate-x-1/2 text-xs font-semibold text-green-300 bg-green-800 p-1 rounded-md">
                  Selected
                </span>
              )}
              <div
                className={`rounded-md ${
                  selectedElement === "description"
                    ? "outline outline-2 outline-green-500"
                    : ""
                }`}
              >
                Enjoy the crisp and refreshing taste of our hand-picked apples. Perfect for a healthy snack!
              </div>
            </p>
            {/* Price and Add to Cart Button in the same row */}
            <div className="flex items-center justify-between mt-2">
              <div
                onClick={() => handleSelect("price")}
                className="relative text-md text-black font-semibold cursor-pointer"
              >
                {selectedElement === "price" && (
                  <span className="absolute top-[-16px] left-1/2 transform -translate-x-1/2 text-xs font-semibold text-green-300 bg-green-800 p-1 rounded-md">
                    Selected
                  </span>
                )}
                <div
                  className={`rounded-md ${
                    selectedElement === "price"
                      ? "outline outline-2 outline-green-500"
                      : ""
                  }`}
                >
                  $2.99
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click propagation to the entire card
                  handleSelect("addToCart"); // Select the button
                }}
                className={`relative flex items-center text-sm text-white bg-gray-600 px-3 py-2 rounded-md ${
                  selectedElement === "addToCart"
                    ? "outline outline-2 outline-green-500"
                    : ""
                }`}
              >
                {selectedElement === "addToCart" && (
                  <span className="absolute top-[-16px] left-1/2 transform -translate-x-1/2 text-xs font-semibold text-green-300 bg-green-800 p-1 rounded-md">
                    Selected
                  </span>
                )}
                <FontAwesomeIcon icon={faShoppingCart} className="mr-1" />
              </button>
            </div>
          </div>
        </div>
      ),
    },
    {
      number: "3",
      title: "Define Variations",
      description:
      "Explore and refine different variations. Our AI will help you generate good variant ideas.",
      visual: (
        <div className="w-full h-auto bg-white rounded-lg py-4 text-black relative flex flex-col items-start gap-3">
          {/* First Variation - Default ("Fresh Apple") */}
          <div className="w-full bg-gray-200 p-3 rounded-lg shadow-md border-2 border-green-500 transition-all cursor-pointer">
            <span className="text-md  font-semibold">Fresh Apple</span>
          </div>

          {/* Variation Options */}
          <div className="w-full space-y-3">
            {variations.map((variation) => (
              <div
                key={variation.id}
                className="flex items-center justify-between bg-gray-200 p-3 rounded-lg shadow-md border-2 border-transparent hover:border-blue-500 transition-all cursor-pointer"
              >
                <span className="text-md font-semibold">{variation.name}</span>
                <div className="flex gap-3">
                  <FontAwesomeIcon
                    icon={faSyncAlt}
                    className="text-blue-400 hover:text-blue-500 cursor-pointer"
                  />
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="text-yellow-400 hover:text-yellow-500 cursor-pointer"
                  />
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="text-red-400 hover:text-red-500 cursor-pointer"
                  />
                </div>
              </div>
            ))}
          </div>

          {/* Generate and Confirm Buttons */}
          <div className="flex justify-end space-x-3 mt-4">
            <button
              onClick={handleGenerateVariation}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Generate
            </button>
            <button className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-full hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500">
              Confirm
            </button>
          </div>
        </div>
      ),
    },
    {
      number: "4",
      title: "Easy Integration",
      description:
        "Add the tag to your website and our platoform will take care of the rest.",
      visual: (
        <div className="w-full h-auto bg-white rounded-lg p-4 text-gray-500 relative flex flex-col gap-4">
          {/* Script Input Field with Copy Button */}
          <div className="flex items-center bg-gray-100 text-gray rounded-md p-2">
            <input
              type="text"
              value={`<script src="https://ctaform.com/ab-testing.js"></script>`}
              readOnly
              className="flex-grow bg-transparent text-sm text-black p-2 focus:outline-none"
            />
            <button className="ml-3 px-4 py-2text-xs font-medium text-black bg-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-100">
              Copy
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <section className="flex justify-center p-4 pb-12 md:pb-24 lg:pb-32">
      <div className="w-full max-w-6xl">
        <h2 className="text-black text-xl text-center mb-20">
          Get tests running in 15 minutes
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-20">
          {steps.map((step) => (
            <div key={step.number} className="rounded-lg border shadow-sm bg-white relative p-6">
              <h3 className="px-6 tracking-tight text-2xl md:text-3xl font-bold text-black mt-2">
              {step.number}. {step.title}
              </h3>
              <p className="px-6 text-gray-600 text-sm mt-4 mb-6">
                {step.description}
              </p>
              <div className="px-6">{step.visual}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OnboardingSection;