import React from "react";
import phoneFrame from '../../src/media/phone_frame.png'; // Assuming you want to use the same phone frame background
import profilePhoto from '../../src/media/linksy-transparent.png'; // Assuming you have a profile photo to import
import ImageGrid from '../components/ImageGrid'; // Import the new ImageGrid component

const Demo = () => {
  // Dummy profile data in JSON format
  const profile = {
    name: "Linksy",
    email: "max.lassila@icloud.com",
    phone: "0458778993",
    socialLinks: {
      instagram: "https://www.instagram.com/maxlassila",
      facebook: "https://www.facebook.com/maxlassila",
      tiktok: "https://www.tiktok.com/@maxlassila",
      linkedin: "https://www.linkedin.com/in/maxlassila",
      github: "https://github.com/maxlassila"
    }
  };

  // Dummy links
  const links = [
    { title: "Book a demo", url: "#" },
    { title: "Feature Catalog", url: "#" },
    { title: "5 Tips for Boosting Your Sales", url: "#" },
  ];

  // Dummy images for the grid (replace these with actual image URLs or imports)
  const images = [
    "https://cdn.midjourney.com/addb9a4f-2083-44c7-bb4d-4033043ea491/0_2.png",
    "https://cdn.midjourney.com/03332863-edac-4c38-bc47-eefd837ce64d/0_3.png",
    "https://cdn.midjourney.com/17735852-ce04-441c-b942-bf47fc22e4ee/0_2.png",
    "https://cdn.midjourney.com/a3c7b6bf-4402-4a7c-8deb-e5f09233a459/0_1.png",
    "https://cdn.midjourney.com/41ea4a62-52d6-4331-b716-af9d27da1ff2/0_0.png",
    "https://cdn.midjourney.com/556dbcb8-5db9-466c-a6b8-052e0719b110/0_2.png",
  ];

  return (
    <div className="m-3 relative w-[320px] h-[620px] mx-auto">
      {/* Phone frame as a background image */}
      <img 
        src={phoneFrame} 
        alt="Phone Frame" 
        className="absolute bg-gray-100 inset-0 w-full h-full object-contain pointer-events-none" 
      />

      {/* Content inside the phone, perfectly fitting inside the transparent area */}
      <div 
        className="absolute top-[5%] left-[12%] w-[76%] h-[77%] overflow-y-auto"
      >
        {/* Profile Info */}
        <div className="flex flex-col items-center mt-5">
          {/* Circular Profile Photo with aspect ratio maintained */}
          <img 
            src={profilePhoto} 
            alt="Profile" 
            className="w-20 rounded-full object-cover" 
          />

          <p className="text-sm text-gray-600 font-bold text-center mb-1">Linksy</p>
          <p className="text-xs text-gray-500 text-center">Leverage organic traffic to gain insights into which keywords and design elements resonate with your audience.</p>
        </div>

        {/* Static Links */}
        <div className="mt-6">
          <ul className="space-y-4">
            {links.map((link, index) => (
              <li key={index}>
                <a 
                  href={link.url} 
                  className="block bg-gray-50 text-sm text-center p-2 rounded-lg shadow hover:bg-gray-100 transition"
                >
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Image Grid */}
        <ImageGrid images={images} />
      </div>
    </div>
  );
};

export default Demo;
