// DesktopLayout.js
import React from "react";
import DonationForm from "./DonationForm";
import OverlayContent from "./OverlayContent";

const DesktopLayout = ({
  charityData,
  isMonthly,
  setIsMonthly,
  selectedAmount,
  setSelectedAmount,
  spacingClasses,
}) => (
  <>
    {/* Left Side - Image and Content for Desktop */}
    <div
      className={`relative flex-1 h-screen lg:h-auto bg-cover bg-center hidden lg:flex ${spacingClasses}`}
      style={{ backgroundImage: `url(${charityData.backgroundImageUrl})` }}
    >
      <OverlayContent charityData={charityData} />
    </div>

    {/* Right Side - Donation Form for Desktop */}
    <div
      className={`hidden lg:flex flex-1 flex-col justify-center bg-black ${spacingClasses} text-white space-y-6`}
      style={{ "--primary-color": charityData.primaryColor }}
    >
      <DonationForm
        charityData={charityData}
        isMonthly={isMonthly}
        setIsMonthly={setIsMonthly}
        selectedAmount={selectedAmount}
        setSelectedAmount={setSelectedAmount}
      />
    </div>
  </>
);

export default DesktopLayout;
