import React, { useState, useEffect } from "react";
import ImageSection from "./ImageSection";
import CallToActionButton from "./CallToActionButton";
import DonationForm from "./DonationForm";

const DonationSection = ({ charityData }) => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [selectedAmount, setSelectedAmount] = useState(null);

  useEffect(() => {
    setSelectedAmount(
      isMonthly
        ? charityData.monthlyDonationOptions[Math.floor(charityData.monthlyDonationOptions.length / 2)].amount
        : charityData.oneTimeDonationOptions[Math.floor(charityData.oneTimeDonationOptions.length / 2)].amount
    );
  }, [isMonthly, charityData]);

  const handleScrollToForm = () => {
    document.getElementById("donationForm").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="max-w-sm mx-auto shadow-md relative bg-black rounded-lg" style={{ "--primary-color": charityData.primaryColor }}>
      <ImageSection charityData={charityData} />
      <CallToActionButton onClick={handleScrollToForm} buttonText="Support Now" />
      <div id="donationForm">
        <DonationForm
          charityData={charityData}
          isMonthly={isMonthly}
          setIsMonthly={setIsMonthly}
          selectedAmount={selectedAmount}
          setSelectedAmount={setSelectedAmount}
        />
      </div>
    </div>
  );
};

export default DonationSection;
