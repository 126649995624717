import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

// Function to get the current day and time
const getCurrentDayAndTime = () => {
  const date = new Date();
  const day = date.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
  const hours = date.getHours();
  return { day, hours };
};

// Function to determine if the store is open
const isOpen = (day, hours) => {
  if (day >= 1 && day <= 5) {
    // Monday to Friday: Open from 9 AM to 6 PM
    return hours >= 9 && hours < 18;
  } else if (day === 6 || day === 0) {
    // Saturday and Sunday: Open from 10 AM to 4 PM
    return hours >= 10 && hours < 16;
  }
  return false;
};

const OpeningTimes = () => {
  const [currentStatus, setCurrentStatus] = useState("Closed");

  useEffect(() => {
    const { day, hours } = getCurrentDayAndTime();
    const open = isOpen(day, hours);
    setCurrentStatus(open ? "Open" : "Closed");
  }, []);

  return (
    <div className="p-4 bg-gray-50 rounded-lg shadow-md w-64 mx-auto flex flex-col justify-between min-h-[235px]">
      {/* Widget Title with FontAwesome Icon */}
      <h3 className="text-lg font-semibold mb-1 text-gray-800">
        <FontAwesomeIcon icon={faClock} className="mr-2" />
        Opening Hours
      </h3>

      {/* Short Description */}
      <p className="text-sm text-gray-600 my-4">
        Display opening status and opening times.
      </p>

      {/* Inner Child Card: App Icon Style */}
      <div className="bg-white border border-gray-200 rounded-lg shadow-inner relative">
        {/* Opening Status at the top */}
        <div
          className={`py-2 rounded-t-lg text-sm font-bold text-white text-center ${
            currentStatus === "Open"
              ? "bg-green-500 text-gray-800"
              : "bg-red-200 text-red-700"
          }`}
        >
          {currentStatus}!
        </div>
        {/* Opening Times */}
        <div className="text-gray-600 p-3 mr-3 ml-1">
          <div className="flex justify-between">
            <p className="text-sm">Mon - Fri</p>
            <p className="text-sm font-bold">9:00-16:00</p>
          </div>
          <div className="flex justify-between mt-2">
            <p className="text-sm">Weekends</p>
            <p className="text-sm font-bold">10:00-14:00</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpeningTimes;
