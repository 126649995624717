// components/ImageGrid.js
import React from "react";

const ImageGrid = ({ images }) => {
  return (
    <div className="mt-6">
      <div className="grid grid-cols-3 gap-2">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index}`}
            className="w-full h-auto rounded-lg shadow"
          />
        ))}
      </div>
    </div>
  );
};

export default ImageGrid;
