import React from "react";

const ImageSection = ({ charityData }) => {
  return (
    <div className="relative w-full overflow-hidden">
      {/* Background Image Section with specific height */}
      <div
        className="w-full h-60 bg-cover bg-center"
        style={{
          backgroundImage: `
            linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.9) 85%),
            linear-gradient(to left, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.8) 100%),
            url(${charityData.backgroundImageUrl})
          `,
        }}
      >
        {/* Profile picture positioned at the top left */}
        {charityData.profilePictureUrl && (
          <img
            src={charityData.profilePictureUrl}
            alt="Profile"
            className="w-12 h-12 rounded-full border-2 bg-white p-1 border-white absolute top-3 left-3 shadow-lg"
            style={{ objectFit: "cover" }}
          />
        )}
      </div>

      {/* Title Section */}
      <h2 className="text-2xl font-bold text-white px-6 mt-4">
        {charityData.name}
      </h2>

      {/* Description Section */}
      <p className="text-lg text-white px-6 py-4 bg-black bg-opacity-75">
        {charityData.description}
      </p>
    </div>
  );
};

export default ImageSection;
