import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const StoreLocator = () => (
  <div className="p-4 bg-gray-50 rounded-lg shadow-md w-64 mx-auto flex flex-col justify-between min-h-[250px]">
    {/* Locator Title with Icon */}
    <h3 className="text-lg font-semibold mb-1 text-gray-800 flex items-center">
      <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
      Store Locator
    </h3>

    {/* Short Description */}
    <p className="text-sm text-gray-600 my-4">
      Find the nearest store and navigate with ease.
    </p>

    {/* Background for Buttons */}
    <div className="bg-white border border-gray-200 rounded-lg shadow-inner relative p-4 text-center">
      
      {/* Store Name "Helsinki Store" */}
      <h4 className="text-sm font-semibold text-gray-800 mb-2">The Store</h4>

      {/* Address in two lines "Museokatu 24, 00100 Helsinki". Underlined style */}
      <p className="text-gray-700 hover:underline text-xs mb-4">
        Museokatu 24
      </p>

      
      {/* Button to Find Closest Store */}
      <button className="bg-blue-500 text-white text-sm font-bold py-2 px-12 rounded-full shadow-lg hover:bg-blue-600 transition-all mb-2">
        Find Closest
      </button>
      
      {/* Button to Open in Maps */}
      <button className="bg-green-600 text-white text-sm font-bold py-2 px-12 rounded-full shadow-lg hover:bg-green-700 transition-all">
        Open in Maps
      </button>
    </div>
  </div>
);

export default StoreLocator;
