import React, { useState, useEffect, useRef } from "react";

const AmountSelection = ({ donationOptions, selectedAmount, setSelectedAmount, primaryColor, placeholderAmount }) => {
  const [isCustom, setIsCustom] = useState(false);
  const customInputRef = useRef(null); // Create a ref for the custom input field

  // Set the default selected amount to the first option on initial render, only if not in custom mode
  useEffect(() => {
    if ((selectedAmount === null || selectedAmount === "") && !isCustom) {
      setSelectedAmount(donationOptions[0].amount);
    }
  }, [donationOptions, selectedAmount, setSelectedAmount, isCustom]);

  const handleAmountClick = (amount) => {
    // Set to the clicked amount and disable custom mode
    setIsCustom(false);
    setSelectedAmount(amount);
  };

  const handleCustomClick = () => {
    // Enable custom input and clear selected amount to make input field empty
    setIsCustom(true);
    setSelectedAmount(""); // Clear the input field

    // Focus the input field when custom option is selected
    setTimeout(() => {
      customInputRef.current?.focus();
    }, 0); // Ensure the input field is focused after state updates
  };

  const handleCustomAmountChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      // Allow the input to be completely empty
      setSelectedAmount("");
    } else {
      // Parse the value as a float only if it's a valid number
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        setSelectedAmount(parsedValue);
      }
    }
  };

  return (
    <div className="mt-4">
      <label className="text-lg font-semibold text-white">Choose an amount</label>
      <div className="flex justify-between mt-2">
        {donationOptions.map((option, index) => (
          <button
            key={index}
            onClick={() => handleAmountClick(option.amount)}
            className={`flex-1 mx-1 px-4 py-2 border-2 text-xl font-semibold ${
              selectedAmount === option.amount && !isCustom ? "text-white" : ""
            }`}
            style={{
              backgroundColor:
                selectedAmount === option.amount && !isCustom
                  ? primaryColor
                  : "white",
              borderColor: primaryColor,
              color:
                selectedAmount === option.amount && !isCustom
                  ? "white"
                  : primaryColor,
            }}
          >
            {option.amount}
            {option.currency}
          </button>
        ))}

        {/* Custom amount button with inline input */}
        <button
          onClick={handleCustomClick}
          className={`flex-1 mx-1 px-4 py-2 border-2 text-xl font-semibold flex items-center justify-center ${
            isCustom ? "text-white" : ""
          }`}
          style={{
            backgroundColor: isCustom ? primaryColor : "white",
            borderColor: primaryColor,
            color: isCustom ? "white" : primaryColor,
          }}
        >
          {isCustom ? (
            <div className="flex items-center justify-center">
              <input
                type="text" // Use text input to allow an empty state
                placeholder={`${placeholderAmount}`} // Use dynamic placeholder
                value={selectedAmount === "" ? "" : selectedAmount}
                onChange={handleCustomAmountChange}
                ref={customInputRef} // Attach ref to the input
                className="w-10 text-center bg-transparent text-white border-none focus:outline-none"
                style={{ color: "white" }}
              />
              <span className="text-white">{donationOptions[0].currency}</span>
            </div>
          ) : (
            "...€"
          )}
        </button>
      </div>
    </div>
  );
};

export default AmountSelection;
