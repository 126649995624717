import React from 'react';
import logo3D from '../media/linksytransparent.png'; // Import 3D logo

const Header = () => {
  return (
    <header className="w-full border-b border-gray-300" style={{ backgroundColor: "#FAF9F6" }}>
      <div className="flex items-center justify-between px-5 py-3">
        {/* Logo Section */}
        <div className="flex items-center space-x-2">
          {/* 3D Logo Image */}
          <img
            src={logo3D}
            alt="Linksy 3D Logo"
            className="h-5 w-5" // Adjust size as needed
          />
          {/* Logo Text as regular text */}
          <span className="text-gray-900 text-md font-semibold">CTAForm</span>
        </div>

        {/* Button Section */}
        <div>
          {/* Sign Up For Free Button */}
          <button className="text-xs font-semibold text-white bg-black py-2 px-3 rounded-3xl hover:bg-blue-700 transition">
            Sign Up For Free
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
