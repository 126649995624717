import React, { useState } from "react";

const ProfileEditor = ({ formData, setFormData, saveProfile }) => {
  const [isProfileCollapsed, setIsProfileCollapsed] = useState(true);

  const handleProfileChange = (key, value) => {
    setFormData({ ...formData, [key]: value });
  };

  return (
    <div className="p-6">
      {/* Modern Collapsible Profile Settings */}
      <div
        className="bg-blue-500 text-white p-2 mb-4 rounded cursor-pointer flex justify-between items-center"
        onClick={() => setIsProfileCollapsed(!isProfileCollapsed)}
      >
        <span>Profile Settings</span>
        <span>{isProfileCollapsed ? "▼" : "▲"}</span>
      </div>

      {!isProfileCollapsed && (
        <div className="mb-6 bg-gray-100 p-4 rounded shadow">
          <h3 className="text-lg font-bold mb-2">Edit Profile</h3>

          <input
            type="text"
            value={formData.name || ""}
            onChange={(e) => handleProfileChange("name", e.target.value)}
            placeholder="Profile Name"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
          />
          <input
            type="text"
            value={formData.logoURL || ""}
            onChange={(e) => handleProfileChange("logoURL", e.target.value)}
            placeholder="Profile Logo URL"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
          />
          <textarea
            value={formData.bio || ""}
            onChange={(e) => handleProfileChange("bio", e.target.value)}
            placeholder="Profile Bio"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
          />
          {/* Additional profile fields (phone, email, etc.) can go here */}
          <input
            type="text"
            value={formData.email || ""}
            onChange={(e) => handleProfileChange("email", e.target.value)}
            placeholder="Profile Email"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />
            <input
            type="text"
            value={formData.phone || ""}
            onChange={(e) => handleProfileChange("phone", e.target.value)}
            placeholder="Profile Phone"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.instagramUrl || ""}
            onChange={(e) => handleProfileChange("instagramUrl", e.target.value)}
            placeholder="Profile Instagram URL"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.youtubeUrl || ""}
            onChange={(e) => handleProfileChange("youtubeUrl", e.target.value)}
            placeholder="Profile Youtube URL"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />
            

            <input
            type="text"
            value={formData.tiktokUrl || ""}
            onChange={(e) => handleProfileChange("tiktokUrl", e.target.value)}
            placeholder="Profile Tiktok URL"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.linkedinUrl || ""}
            onChange={(e) => handleProfileChange("linkedinUrl", e.target.value)}
            placeholder="Profile LinkedIn URL"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.snapchatUrl || ""}
            onChange={(e) => handleProfileChange("snapchatUrl", e.target.value)}
            placeholder="Profile Snapchat URL"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.linkBgColor || ""}
            onChange={(e) => handleProfileChange("linkBgColor", e.target.value)}
            placeholder="Profile Link Background Color"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.linkBorderColor || ""}
            onChange={(e) => handleProfileChange("linkBorderColor", e.target.value)}
            placeholder="Profile Link Border Color"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.profileTextColor || ""}
            onChange={(e) => handleProfileChange("profileTextColor", e.target.value)}
            placeholder="Profile Text Color"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.profileBgColor || ""}
            onChange={(e) => handleProfileChange("profileBgColor", e.target.value)}
            placeholder="Profile Background Color"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.profileBioColor || ""}
            onChange={(e) => handleProfileChange("profileBioColor", e.target.value)}
            placeholder="Profile Bio Text Color"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.linkTextColor || ""}
            onChange={(e) => handleProfileChange("linkTextColor", e.target.value)}
            placeholder="Profile Link Text Color"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.formTitle || ""}
            onChange={(e) => handleProfileChange("formTitle", e.target.value)}
            placeholder="Profile Form Title"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.formDescription || ""}
            onChange={(e) => handleProfileChange("formDescription", e.target.value)}
            placeholder="Profile Form Description"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.formInputPlaceholder || ""}
            onChange={(e) => handleProfileChange("formInputPlaceholder", e.target.value)}
            placeholder="Profile Form Input Placeholder"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />

            <input
            type="text"
            value={formData.formSubmitButtonText || ""}
            onChange={(e) => handleProfileChange("formSubmitButtonText", e.target.value)}
            placeholder="Profile Form Submit Button Text"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
            />
            
            
          
          <button
            className="w-full bg-blue-500 text-white p-2 rounded"
            onClick={saveProfile}
          >
            Save Profile
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileEditor;
