import TestDemo from "../components/TestDemo"; // CTA section for generating link in bio
import AccordionSection from "../components/core/AccordionSection";
import ComparisonSection from "../components/ComparisonSection";
import OnboardingSection from "../components/OnboardingSection";
import SubscriptionPlans from "../components/SubscriptionPlans";
import HeroSection from "../components/HeroSection";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Landing = () => {
  return (
    <div className="bg-[#FAF9F6] min-h-screen font-sans overflow-x-hidden">
      {/* Header */}
      <Header />

      {/* Hero Section */}
      <section className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-[#FAF9F6] to-purple-200">
        <HeroSection />
      </section>

      {/* Onboarding Section */}
      <section className="w-full max-w-full overflow-x-hidden min-h-screen flex items-center justify-center bg-gradient-to-b from-purple-200 to-purple-300">
        <OnboardingSection />
      </section>

      {/* CTA Section */}
      <section className="w-full max-w-full overflow-x-hidden min-h-screen flex items-center justify-center bg-gradient-to-b from-purple-300 to-purple-500">
        <TestDemo />
      </section>

      {/* Comparison Section */}
      <section className="w-full max-w-full overflow-x-hidden min-h-screen flex items-center justify-center bg-gradient-to-b from-purple-500 to-purple-700">
        <ComparisonSection />
      </section>

      {/* Subscription Plans */}
      <section className="w-full max-w-full overflow-x-hidden min-h-screen flex items-center justify-center bg-gradient-to-b from-purple-700 to-gray-900">
        <SubscriptionPlans />
      </section>

      {/* Features Section */}
      <section className="w-full max-w-full min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-900 to-gray-800">
        <AccordionSection />
      </section>

      {/* Footer */}
      <section className="w-full max-w-full min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-800 to-gray-900">
        <Footer />
      </section>
    </div>
  );
};

export default Landing;
