import React from "react";
import { scroller } from "react-scroll";
import ImageSection from "./ImageSection";
import DonationForm from "./DonationForm";
import CallToActionButton from "./CallToActionButton";

const MobileLayout = ({
  charityData,
  isMonthly,
  setIsMonthly,
  selectedAmount,
  setSelectedAmount,
  spacingClasses,
}) => {
  const handleButtonClick = () => {
    scroller.scrollTo("donationForm", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <>
      {/* First viewport section with Image and CTA Button */}
      <div className="lg:hidden h-screen flex flex-col justify-start"> {/* Removed justify-center */}
        <ImageSection charityData={charityData} />
        <div className="px-3 py-4">
          <CallToActionButton
            onClick={handleButtonClick}
            buttonText={charityData.buttonText}
            primaryColor={charityData.primaryColor}
          />
        </div>
      </div>

      {/* Donation Form section, always rendered below the first viewport */}
      <div
        id="donationForm"
        className={`lg:hidden ${spacingClasses} space-y-6 bg-black text-white p-6`}
        style={{ "--primary-color": charityData.primaryColor }}
      >
        <DonationForm
          charityData={charityData}
          isMonthly={isMonthly}
          setIsMonthly={setIsMonthly}
          selectedAmount={selectedAmount}
          setSelectedAmount={setSelectedAmount}
        />
      </div>
      <div className="h-80 bg-black"></div>

    </>
  );
};

export default MobileLayout;
