import React from 'react';

const ImageCardWidget = ({ imageUrl, title, description }) => {
  return (
    <div className="relative bg-white rounded-lg shadow-md overflow-hidden">
      {/* Image */}
      <img src={imageUrl} alt={title} className="w-full h-64 object-cover" />

{/* Premium Badge */}
<div className="absolute inset-0 flex items-center justify-center">
  <div className="bg-gray-100 text-black text-2xl font-bold rounded-full h-20 w-20 flex items-center justify-center mb-10">
    A
  </div>
</div>






      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent p-4 flex flex-col justify-end">
        <h2 className="text-lg font-bold text-white text-shadow-lg">{title}</h2>
        <p className="text-sm text-gray-200 mt-1">{description}</p>
      </div>
    </div>
  );
};

export default ImageCardWidget;
