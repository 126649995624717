import React, { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import LinkEditor from "../components/core/LinkEditor";
import LivePreview from "../components/core/LivePreview";
import ProfileEditor from "../components/core/ProfileEditor";
import AddLink from "../components/core/AddLink";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const client = generateClient();

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    bio: "",
    logoURL: "",
    phone: "",
    email: "",
    instagramUrl: "",
    tiktokUrl: "",
    facebookUrl: "",
    linkedinUrl: "",
    snapchatUrl: "",
    linkBgColor: "",
    linkTextColor: "",
    formTitle: "",
    formDescription: "",
    formInputPlaceholder: "",
    formSubmitButtonText: "",
  });
  const [links, setLinks] = useState([]);
  const [newLink, setNewLink] = useState({
    title: "",
    url: "",
    imageUrl: "",
    description: "",
    index: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProfile();
  }, []);

  async function fetchProfile() {
    try {
      const profileData = await client.graphql({ query: queries.listProfiles });
      const profiles = profileData.data.listProfiles.items;

      if (profiles.length > 0) {
        const profile = profiles[0];
        setProfile(profile);
        setFormData({
          id: profile.id,
          name: profile.name,
          bio: profile.bio,
          logoURL: profile.logoURL,
          phone: profile.phone,
          email: profile.email,
          instagramUrl: profile.instagramUrl,
          tiktokUrl: profile.tiktokUrl,
          facebookUrl: profile.facebookUrl,
          linkedinUrl: profile.linkedinUrl,
          snapchatUrl: profile.snapchatUrl,
          linkBgColor: profile.linkBgColor,
          linkTextColor: profile.linkTextColor,
          formTitle: profile.formTitle,
          formDescription: profile.formDescription,
          formInputPlaceholder: profile.formInputPlaceholder,
          formSubmitButtonText: profile.formSubmitButtonText,
        });
        fetchLinks(profile.id);
      } else {
        // No profile found, setting up for a new profile
        setProfile(null); // Ensuring empty state for new profile creation
      }
    } catch (err) {
      console.error("Error fetching profile:", err);
    } finally {
      setLoading(false);
    }
  }

  async function fetchLinks(profileID) {
    try {
      const linkData = await client.graphql({
        query: queries.listLinks,
        variables: { filter: { profileID: { eq: profileID } } },
      });
      const fetchedLinks = linkData.data.listLinks.items || [];
      setLinks(fetchedLinks);
    } catch (err) {
      console.error("Error fetching links:", err);
    }
  }

  const saveProfile = async () => {
    try {
      let {
        id,
        name,
        bio,
        logoURL,
        phone,
        email,
        instagramUrl,
        tiktokUrl,
        facebookUrl,
        linkedinUrl,
        snapchatUrl,
        linkBgColor,
        linkTextColor,
        formTitle,
        formDescription,
        formInputPlaceholder,
        formSubmitButtonText,
      } = formData;

      // Ensure name is lowercase and use it as the ID
      if (!id && name) {
        id = name.toLowerCase(); // Set the ID as the lowercase name
      }

      const input = {
        id, // The profile ID is the lowercase name
        name,
        bio,
        logoURL,
        phone,
        email,
        instagramUrl,
        tiktokUrl,
        facebookUrl,
        linkedinUrl,
        snapchatUrl,
        linkBgColor,
        linkTextColor,
        formTitle,
        formDescription,
        formInputPlaceholder,
        formSubmitButtonText,
      };

      if (!profile) {
        // Creating a new profile
        const result = await client.graphql({
          query: mutations.createProfile,
          variables: { input },
        });
        setProfile(result.data.createProfile);
        setFormData({ ...formData, id: result.data.createProfile.id });
        alert("Profile created successfully!");
      } else {
        // Updating an existing profile
        await client.graphql({
          query: mutations.updateProfile,
          variables: { input },
        });
        alert("Profile updated successfully!");
      }
    } catch (err) {
      console.error("Error saving profile:", err);
      alert("Error saving profile. Please try again.");
    }
  };

  const saveLink = async (linkIndex) => {
    const linkToUpdate = links[linkIndex];
    try {
      const { id, title, url, imageUrl, description, index } = linkToUpdate;
      const input = { id, title, url, imageUrl, description, index };

      await client.graphql({
        query: mutations.updateLink,
        variables: { input },
      });

      console.log(`Link with ID: ${id} updated with new index: ${index}`);
    } catch (err) {
      console.error("Error updating link:", err);
      alert("Error updating link. Please try again.");
    }
  };

  const addLink = async () => {
    if (!newLink.title || !newLink.url) {
      alert("Please provide both title and URL.");
      return;
    }

    try {
      const input = {
        profileID: profile.id,
        title: newLink.title,
        url: newLink.url,
        description: newLink.description,
        imageUrl: newLink.imageUrl,
        index: links.length + 1,
      };

      const result = await client.graphql({
        query: mutations.createLink,
        variables: { input },
      });

      setLinks([...links, result.data.createLink]);
      setNewLink({ title: "", url: "", description: "", imageUrl: "", index: "" });
    } catch (err) {
      console.error("Error creating link:", err);
    }
  };

  const updateLink = (index, key, value) => {
    const updatedLinks = [...links];
    updatedLinks[index][key] = value;
    setLinks(updatedLinks);
  };

  const removeLink = async (index) => {
    const linkToRemove = links[index];

    try {
      await client.graphql({
        query: mutations.deleteLink,
        variables: { input: { id: linkToRemove.id } },
      });

      const updatedLinks = links.filter((_, i) => i !== index);
      setLinks(updatedLinks);
    } catch (err) {
      console.error("Error deleting link:", err);
    }
  };

  if (loading) {
    return (
      <div className="flex">
        <div className="w-1/2 p-4">
          <Skeleton height={40} width={100} />
          <Skeleton count={3} />
        </div>
        <div className="w-1/2 p-4">
          <Skeleton height={300} />
        </div>
      </div>
    );
  }
  
  return (
    <div className="flex">
      <div className="w-1/2 p-4">
        {/* Profile Editor */}
        <ProfileEditor
          formData={formData}
          setFormData={setFormData}
          saveProfile={saveProfile}
        />

        {/* Add Link Editor */}
        <AddLink
          links={links}
          setLinks={setLinks}
          newLink={newLink}
          setNewLink={setNewLink}
          addLink={addLink}
          saveLink={saveLink}
        />

        {/* Link Editor */}
        <LinkEditor
          links={links}
          setLinks={setLinks}
          updateLink={updateLink}
          removeLink={removeLink}
          saveLink={saveLink}
        />
      </div>

      {/* Right Side: Live Preview */}
      <div className="w-1/2 p-4">
        {profile ? <LivePreview profile={profile} links={links} /> : <p>No profile available yet</p>}
      </div>
    </div>
  );
};

export default Profile;
