/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const incrementProfileViews = /* GraphQL */ `
  mutation IncrementProfileViews($id: ID!, $increment: Int!) {
    incrementProfileViews(id: $id, increment: $increment) {
      id
      name
      bio
      logoURL
      profileBgColor
      profileTextColor
      profileBioColor
      links {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      phone
      email
      instagramUrl
      tiktokUrl
      facebookUrl
      linkedinUrl
      snapchatUrl
      youtubeUrl
      spotifyUrl
      applePodcastsUrl
      discordUrl
      twitterUrl
      rssUrl
      createdAt
      updatedAt
      linkBgColor
      linkBorderColor
      linkTextColor
      formTitle
      formDescription
      formInputPlaceholder
      formSubmitButtonText
      profileViews
      hasGrid
      owner
      __typename
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      name
      bio
      logoURL
      profileBgColor
      profileTextColor
      profileBioColor
      links {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      phone
      email
      instagramUrl
      tiktokUrl
      facebookUrl
      linkedinUrl
      snapchatUrl
      youtubeUrl
      spotifyUrl
      applePodcastsUrl
      discordUrl
      twitterUrl
      rssUrl
      createdAt
      updatedAt
      linkBgColor
      linkBorderColor
      linkTextColor
      formTitle
      formDescription
      formInputPlaceholder
      formSubmitButtonText
      profileViews
      hasGrid
      owner
      __typename
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      name
      bio
      logoURL
      profileBgColor
      profileTextColor
      profileBioColor
      links {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      phone
      email
      instagramUrl
      tiktokUrl
      facebookUrl
      linkedinUrl
      snapchatUrl
      youtubeUrl
      spotifyUrl
      applePodcastsUrl
      discordUrl
      twitterUrl
      rssUrl
      createdAt
      updatedAt
      linkBgColor
      linkBorderColor
      linkTextColor
      formTitle
      formDescription
      formInputPlaceholder
      formSubmitButtonText
      profileViews
      hasGrid
      owner
      __typename
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      name
      bio
      logoURL
      profileBgColor
      profileTextColor
      profileBioColor
      links {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      phone
      email
      instagramUrl
      tiktokUrl
      facebookUrl
      linkedinUrl
      snapchatUrl
      youtubeUrl
      spotifyUrl
      applePodcastsUrl
      discordUrl
      twitterUrl
      rssUrl
      createdAt
      updatedAt
      linkBgColor
      linkBorderColor
      linkTextColor
      formTitle
      formDescription
      formInputPlaceholder
      formSubmitButtonText
      profileViews
      hasGrid
      owner
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      profileID
      message
      type
      createdAt
      updatedAt
      index
      owner
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      profileID
      message
      type
      createdAt
      updatedAt
      index
      owner
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      profileID
      message
      type
      createdAt
      updatedAt
      index
      owner
      __typename
    }
  }
`;
export const createLink = /* GraphQL */ `
  mutation CreateLink(
    $input: CreateLinkInput!
    $condition: ModelLinkConditionInput
  ) {
    createLink(input: $input, condition: $condition) {
      id
      profileID
      url
      imageUrl
      title
      description
      variants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      index
      totalViews
      totalClicks
      views
      clicks
      hasVariants
      owner
      __typename
    }
  }
`;
export const updateLink = /* GraphQL */ `
  mutation UpdateLink(
    $input: UpdateLinkInput!
    $condition: ModelLinkConditionInput
  ) {
    updateLink(input: $input, condition: $condition) {
      id
      profileID
      url
      imageUrl
      title
      description
      variants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      index
      totalViews
      totalClicks
      views
      clicks
      hasVariants
      owner
      __typename
    }
  }
`;
export const deleteLink = /* GraphQL */ `
  mutation DeleteLink(
    $input: DeleteLinkInput!
    $condition: ModelLinkConditionInput
  ) {
    deleteLink(input: $input, condition: $condition) {
      id
      profileID
      url
      imageUrl
      title
      description
      variants {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      index
      totalViews
      totalClicks
      views
      clicks
      hasVariants
      owner
      __typename
    }
  }
`;
export const createLinkVariant = /* GraphQL */ `
  mutation CreateLinkVariant(
    $input: CreateLinkVariantInput!
    $condition: ModelLinkVariantConditionInput
  ) {
    createLinkVariant(input: $input, condition: $condition) {
      id
      linkID
      url
      imageUrl
      title
      description
      createdAt
      updatedAt
      index
      totalViews
      totalClicks
      views
      clicks
      conversions
      hoverEvents
      timeSpent
      bounceRate
      owner
      __typename
    }
  }
`;
export const updateLinkVariant = /* GraphQL */ `
  mutation UpdateLinkVariant(
    $input: UpdateLinkVariantInput!
    $condition: ModelLinkVariantConditionInput
  ) {
    updateLinkVariant(input: $input, condition: $condition) {
      id
      linkID
      url
      imageUrl
      title
      description
      createdAt
      updatedAt
      index
      totalViews
      totalClicks
      views
      clicks
      conversions
      hoverEvents
      timeSpent
      bounceRate
      owner
      __typename
    }
  }
`;
export const deleteLinkVariant = /* GraphQL */ `
  mutation DeleteLinkVariant(
    $input: DeleteLinkVariantInput!
    $condition: ModelLinkVariantConditionInput
  ) {
    deleteLinkVariant(input: $input, condition: $condition) {
      id
      linkID
      url
      imageUrl
      title
      description
      createdAt
      updatedAt
      index
      totalViews
      totalClicks
      views
      clicks
      conversions
      hoverEvents
      timeSpent
      bounceRate
      owner
      __typename
    }
  }
`;
