import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy, faBarcode } from "@fortawesome/free-solid-svg-icons";
import FinnishBankUtils from "finnish-bank-utils";

const Test2 = () => {
  const charityData = {
    name: "Join the Helping Hands Charity",
    description:
      "Your support goes a long way to help our cause and mission to save children. We are a non-profit organization that relies on donations to provide medical treatment to children in need.",
    profilePictureUrl:
      "https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-512.png",
    oneTimeDonationOptions: [
      { amount: 10, currency: "€" },
      { amount: 20, currency: "€" },
      { amount: 50, currency: "€" },
      { amount: 100, currency: "€" },
    ],
    iban: "FI5810171000000122",
    mobilepayLink: "mobilepayfi://send?phone=77772&comment=Donation",
    colorScheme: {
      primary: "text-purple-600 border-purple-600",
      primaryBg: "bg-purple-600 hover:bg-purple-700 text-white",
      border: "border-gray-300 dark:border-gray-600",
      text: "text-gray-700 dark:text-gray-400",
      label: "text-gray-900 dark:text-white",
      inputBg: "bg-gray-50 dark:bg-gray-700",
    },
  };

  const [selectedAmount, setSelectedAmount] = useState(null);
  const [customAmount, setCustomAmount] = useState("");
  const [isCustomSelected, setIsCustomSelected] = useState(false);
  const [barcode, setBarcode] = useState("");

  const [ibanCopied, setIbanCopied] = useState(false);
  const [barcodeCopied, setBarcodeCopied] = useState(false);

  const handleAmountSelection = (amount) => {
    setSelectedAmount(amount);
    setCustomAmount("");
    setIsCustomSelected(false); // Reset custom input mode
    updateBarcode(amount);
  };

  const handleCustomAmountClick = () => {
    setSelectedAmount(null);
    setIsCustomSelected(true);
  };

  const handleCustomAmountChange = (event) => {
    const amount = event.target.value;
    setCustomAmount(amount);
    setSelectedAmount(null);
    updateBarcode(amount);
  };

  const updateBarcode = (amount) => {
    if (!amount) return;
    const sum = parseFloat(amount).toFixed(1);
    const reference = "55958 22432 94671";
    const date = new Date();
    const formattedDate = `${date.getDate()}.${
      date.getMonth() + 1
    }.${date.getFullYear()}`;
    const generatedBarcode = FinnishBankUtils.formatFinnishVirtualBarCode({
      iban: charityData.iban,
      sum: parseFloat(sum),
      reference: reference,
      date: formattedDate,
    });
    setBarcode(generatedBarcode || "Error generating barcode");
    setBarcodeCopied(false);
  };

  const handleCopy = (text, setCopied) => {
    navigator.clipboard
      .writeText(text)
      .then(() => setCopied(true))
      .catch(() => console.error("Failed to copy text"));
  };

  const handleMobilePayClick = () => {
    const amount = selectedAmount || customAmount;
    const mobilePayUrl = `${charityData.mobilepayLink}&amount=${amount}`;
    window.open(mobilePayUrl, "_blank");
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-100 py-6">
      <div
        className={`w-full max-w-lg bg-white dark:bg-gray-800 border ${charityData.colorScheme.border} shadow rounded-lg p-5`}
      >
        {/* Summary Section */}
        <div className="mb-6">
          <h2
            className={`text-lg font-semibold ${charityData.colorScheme.label} mb-2`}
          >
            {charityData.name}
          </h2>
          <p className={`text-gray-500 dark:text-gray-400`}>
            A one-time donation to support {charityData.name}.
          </p>
        </div>

        {/* Donation Amount Section */}
        <div className="mb-4">
          <h3
            className={`text-sm font-medium ${charityData.colorScheme.label} mb-2`}
          >
            Choose a donation amount
          </h3>
          <div className="flex flex-wrap gap-2">
            {charityData.oneTimeDonationOptions.map((option) => (
              <button
                key={option.amount}
                onClick={() => handleAmountSelection(option.amount)}
                className={`px-4 py-2 rounded-lg border ${
                  selectedAmount === option.amount
                    ? charityData.colorScheme.primary
                    : `${charityData.colorScheme.text} ${charityData.colorScheme.border}`
                } ${
                  selectedAmount === option.amount
                    ? `border-2 ${charityData.colorScheme.primary}`
                    : "border"
                }`}
              >
                <span
                  className={`${
                    selectedAmount === option.amount
                      ? charityData.colorScheme.primary
                      : charityData.colorScheme.text
                  }`}
                >
                  {option.amount} {option.currency}
                </span>
              </button>
            ))}

            {/* Custom Amount Button/Input */}
            {isCustomSelected ? (
              <input
                type="number"
                placeholder="Custom"
                value={customAmount}
                onChange={handleCustomAmountChange}
                className={`px-4 py-2 rounded-lg border ${charityData.colorScheme.text} w-20 ${charityData.colorScheme.inputBg} ${charityData.colorScheme.border}`}
              />
            ) : (
              <button
                onClick={handleCustomAmountClick}
                className={`px-4 py-2 rounded-lg border bg-white ${charityData.colorScheme.text} ${charityData.colorScheme.border}`}
              >
                Custom
              </button>
            )}
          </div>
        </div>

        {/* MobilePay Button */}
        <div className="flex justify-center mb-6">
          <button
            onClick={handleMobilePayClick}
            className={`w-full py-2 px-4 rounded-lg text-lg font-semibold ${charityData.colorScheme.primaryBg}`}
          >
            Pay with MobilePay
          </button>
        </div>

        {/* Barcode Copy Section */}
        <div className="mb-4">
          <h3
            className={`text-sm font-medium ${charityData.colorScheme.label} mb-2`}
          >
            Virtual Barcode
          </h3>
          <div className="flex items-center">
            <input
              type="text"
              value={barcode}
              readOnly
              className={`w-full ${charityData.colorScheme.inputBg} ${charityData.colorScheme.border} text-gray-500 text-sm rounded-r-lg p-2.5`}
            />
            <button
              onClick={() => handleCopy(barcode, setBarcodeCopied)}
              onMouseLeave={() => setBarcodeCopied(false)}
              className={`ml-2 p-2 rounded-lg ${charityData.colorScheme.primaryBg}`}
            >
              {barcodeCopied ? (
                <FontAwesomeIcon icon={faCheck} className="w-4 h-4" />
              ) : (
                <FontAwesomeIcon icon={faCopy} className="w-4 h-4" />
              )}
            </button>
          </div>
        </div>

        {/* IBAN Copy Section */}
        <div className="mb-4">
          <h3
            className={`text-sm font-medium ${charityData.colorScheme.label} mb-2`}
          >
            IBAN
          </h3>
          <div className="flex items-center">
            <input
              type="text"
              value={charityData.iban}
              readOnly
              className={`w-full ${charityData.colorScheme.inputBg} ${charityData.colorScheme.border} text-gray-500 text-sm rounded-r-lg p-2.5`}
            />
            <button
              onClick={() => handleCopy(charityData.iban, setIbanCopied)}
              onMouseLeave={() => setIbanCopied(false)}
              className={`ml-2 p-2 rounded-lg ${charityData.colorScheme.primaryBg}`}
            >
              {ibanCopied ? (
                <FontAwesomeIcon icon={faCheck} className="w-4 h-4" />
              ) : (
                <FontAwesomeIcon icon={faCopy} className="w-4 h-4" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test2;
