import React from 'react';
import phoneFrame from '../../media/phone_frame.png';
import SocialMediaIcons from '../SocialMediaIcons'; // Import the social media component

const LivePreview = ({ profile, links }) => {
  return (
    <div className="m-3 relative w-[320px] h-[620px] mx-auto">
      {/* Phone frame as a background image */}
      <img 
        src={phoneFrame} 
        alt="Phone Frame" 
        className="absolute bg-gray-700 inset-0 w-full h-full object-contain pointer-events-none" 
      />

      {/* Content inside the phone, perfectly fitting inside the transparent area */}
      <div 
        className="absolute top-[15%] left-[12%] w-[76%] h-[77%] overflow-y-auto"
      >
        {/* Profile Info */}
        <div className="flex flex-col items-center mb-6">
          <img 
            src={profile.logoURL} 
            alt="Profile" 
            className="w-12 h-12 rounded-full mb-2" 
          />
          <h3 className="text-md text-gray-100 font-semibold text-center mt-2">{profile.name}</h3>
          <p className="text-md text-gray-100 text-center">{profile.bio}</p>
        </div>

        {/* Links Section */}
        <ul className="space-y-4 mb-10">
          {links
            .sort((a, b) => a.index - b.index)  // Sorting links by index field
            .map((link, index) => (
            <a 
              key={index} 
              href={link.url} 
              className="block bg-gray-50 p-4 rounded-lg shadow hover:bg-gray-200 transition" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <li className={`flex ${!link.imageUrl ? 'justify-center items-center text-center' : 'items-center'}`}>
                {/* Image on the left side */}
                {link.imageUrl && (
                  <img 
                    src={link.imageUrl} 
                    alt={link.title} 
                    className="w-7 h-7 object-cover rounded-lg mr-4"
                  />
                )}
                {/* Link Title and Description */}
                <div className={`${!link.imageUrl ? '' : 'text-left'}`}>
                  <h4 className="text-sm">{link.title}</h4>
                  <p className="text-gray-600">{link.description}</p>
                </div>
              </li>
            </a>
          ))}
        </ul>
        <SocialMediaIcons profile={profile} />
      </div>
      
    </div>
  );
};

export default LivePreview;
