import React, { useState } from "react";

const AddLink = ({ links, setLinks, newLink, setNewLink, addLink, saveLink }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

  const handleInputChange = (key, value) => {
    setNewLink({ ...newLink, [key]: value });
  };

  const handleAddLink = () => {
    if (!newLink.title || !newLink.url) {
      alert("Please fill in both title and URL.");
      return;
    }

    const handleReorder = async (index, direction) => {
        const newLinks = [...links];
      
        // Swap the position of the link with the one above or below
        if (direction === "up" && index > 0) {
          [newLinks[index - 1], newLinks[index]] = [newLinks[index], newLinks[index - 1]];
        } else if (direction === "down" && index < newLinks.length - 1) {
          [newLinks[index], newLinks[index + 1]] = [newLinks[index + 1], newLinks[index]];
        }
      
        // Recalculate indices for each link after reordering
        newLinks.forEach((link, idx) => {
          link.index = idx + 1;
        });
      
        // Update the state with the new order of links
        setLinks(newLinks);
      
        // Update each link in the backend with the new index
        for (let i = 0; i < newLinks.length; i++) {
          await saveLink(i); // Call saveLink for each link to update its index
        }
      };
      

    // Get the highest current index from the links array
    const nextIndex = links.length > 0 ? Math.max(...links.map(link => link.index)) + 1 : 1;

    // Add the new link with the next available index
    const newLinkWithIndex = { ...newLink, index: nextIndex };
    addLink(newLinkWithIndex); // Pass the new link object with the correct index

    setNewLink({ title: "", url: "", imageUrl: "", description: "" }); // Reset new link form fields
    setIsCollapsed(true); // Collapse the form after adding the link
  };

  return (
    <div className="p-6">
      {/* Modern Collapsible Add Link Editor */}
      <div
        className="bg-purple-500 text-white p-2 mb-4 rounded cursor-pointer flex justify-between items-center"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <span>Add New Link</span>
        <span>{isCollapsed ? "▼" : "▲"}</span>
      </div>

      {!isCollapsed && (
        <div className="mb-6 bg-gray-100 p-4 rounded shadow">
          <h3 className="text-lg font-bold mb-2">New Link</h3>

          <input
            type="text"
            value={newLink.title || ""}
            onChange={(e) => handleInputChange("title", e.target.value)}
            placeholder="Link Title"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
          />
          <input
            type="text"
            value={newLink.url || ""}
            onChange={(e) => handleInputChange("url", e.target.value)}
            placeholder="Link URL"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
          />
          <input
            type="text"
            value={newLink.imageUrl || ""}
            onChange={(e) => handleInputChange("imageUrl", e.target.value)}
            placeholder="Image URL"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
          />
          <textarea
            value={newLink.description || ""}
            onChange={(e) => handleInputChange("description", e.target.value)}
            placeholder="Link Description"
            className="w-full mb-4 p-2 border border-gray-300 rounded"
          />

          <div className="flex justify-end">
            <button
              onClick={handleAddLink}
              className="bg-green-500 text-white p-2 rounded mr-2"
            >
              Save Link
            </button>
            <button
              onClick={() => setIsCollapsed(true)}
              className="bg-gray-500 text-white p-2 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddLink;
