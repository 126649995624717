import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const LogGraph = ({ logs }) => {
  const [options, setOptions] = useState({
    chart: {
      id: "visitors-chart",
      type: "line",
      toolbar: { show: false },
    },
    stroke: { width: [3, 3, 3], curve: "smooth" },
    colors: ["#007bff", "#32bd68", "#f5b342"], // Colors for Visitors, Clicks, and CTR
    dataLabels: { enabled: false },
    tooltip: {
      shared: true,
      intersect: false,
      x: { show: true },
      y: {
        formatter: (value, { seriesIndex }) => {
          const label = seriesIndex === 0 ? "Visitors" : seriesIndex === 1 ? "Clicks" : "CTR";
          return `${label}: ${value}`;
        },
      },
    },
    xaxis: {
      categories: [], // Will be dynamically populated
      labels: {
        style: { fontSize: "12px" },
        formatter: (val) => new Date(val).toLocaleDateString("fi-FI", { day: "2-digit", month: "2-digit" }), // Format as dd.mm
      },
    },
    yaxis: { labels: { style: { fontSize: "12px" } } },
    legend: { position: "bottom" },
  });

  const [series, setSeries] = useState([
    { name: "Visitors", data: [] },
    { name: "Clicks", data: [] },
    { name: "CTR", data: [] },
  ]);

  useEffect(() => {
    const dates = logs.map((log) => log.date);
    const visitors = logs.map((log) => log.visitors);
    const clicks = logs.map((log) => log.clicks);
    const ctr = logs.map((log) => ((log.clicks / log.visitors) * 100).toFixed(1)); // Calculate CTR

    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: { ...prevOptions.xaxis, categories: dates },
    }));

    setSeries([
      { name: "Visitors", data: visitors },
      { name: "Clicks", data: clicks },
      { name: "CTR", data: ctr },
    ]);
  }, [logs]);

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg w-full max-w-md mx-auto mb-6">
      <h2 className="text-lg font-bold text-center mb-4">Daily Visitors and Clicks</h2>
      <ReactApexChart options={options} series={series} type="line" height={300} />
    </div>
  );
};

const BarChart = ({ keywords }) => {
  const [options, setOptions] = useState({
    chart: { type: "bar", toolbar: { show: false } },
    plotOptions: {
      bar: { horizontal: true, barHeight: "70%", distributed: true },
    },
    colors: ["#32bd68", "#8bcdff", "#ffce00", "#ffa41b", "#ff6347"], // Gradient for performance
    xaxis: {
      categories: keywords.map((k) => k.keyword),
      labels: { style: { fontSize: "12px" } },
    },
  });

  const [series, setSeries] = useState([{ name: "Performance", data: keywords.map((k) => k.performance) }]);

  return (
    <div className="p-4 bg-white rounded-lg shadow-lg w-full max-w-md mx-auto">
      <h2 className="text-lg font-bold text-center mb-4">Keyword Performance</h2>
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

const Dashboard = () => {
  const logs = [
    { date: "2024-10-01", visitors: 120, clicks: 80 },
    { date: "2024-10-02", visitors: 140, clicks: 90 },
    { date: "2024-10-03", visitors: 100, clicks: 50 },
    { date: "2024-10-04", visitors: 130, clicks: 70 },
    { date: "2024-10-05", visitors: 150, clicks: 110 },
  ];

  const keywords = [
    { keyword: "Brand Visibility", performance: 100 },
    { keyword: "Optimize Links", performance: 80 },
    { keyword: "Increase Clicks", performance: 60 },
    { keyword: "Improve CTR", performance: 40 },
    { keyword: "Link Insights", performance: 20 },
  ];

  return (
    <div className="container mx-auto p-4 md:p-8">
      <LogGraph logs={logs} />
      <BarChart keywords={keywords} />
    </div>
  );
};

export default Dashboard;
