import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './BookingWidget.css';

const BookingWidget = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  return (
    <div className="p-4 bg-gray-50 rounded-lg shadow-lg w-full max-w-xs mx-auto flex flex-col justify-between min-h-[350px]">
      {/* Widget Title */}
      <div className="mb-4">
        <h3 className="text-lg font-semibold text-gray-800 mb-2 flex items-center ">
          <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-gray-800" />
          Booking Widget
        </h3>
        <p className="text-sm text-gray-600">
          Engage customers by letting them book appointments independently.
        </p>
      </div>

      {/* Date Picker Container */}
      <div className="flex justify-center">
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          inline
          calendarStartDay={1} // Start on Monday
          className="custom-datepicker" // Custom styling for datepicker
        />
      </div>

      {/* Call to Action Button */}
      <div className="mt-4 flex justify-center">
        <button className="bg-blue-500 text-white py-2 px-6 rounded-full shadow-lg hover:bg-blue-600 transition">
          Book Now
        </button>
      </div>
    </div>
  );
};

export default BookingWidget;
