import React from "react";
import OpeningTimes from "../components/OpeningTimes";
import StoreLocator from "../components/StoreLocator";
import InstagramFeed from "../components/InstagramFeed";
import BookingWidget from "../components/BookingWidget";
import ContactFormWidget from "../components/ContactFormWidget";
import LatestReleaseWidget from "../components/LatestReleaseWidget";
import CountdownWidget from "../components/CountDownWidget";
import DonationWidget from "../components/DonationWidget";
import InsightCarousel from "../components/InsightCarousel";
import AppInstallWidget from "../components/AppInstallWidget";
import ImageCardWidget from "../components/ImageCardWidget"; // Import the new ImageCardWidget

const Showcase = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        {/* Page Heading */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">
            Explore Our Widgets
          </h1>
          <p className="text-lg text-gray-600">
            Explore a variety of customizable widgets available for integration.
          </p>
        </div>

        {/* Display Widgets Directly */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <OpeningTimes />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <StoreLocator />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <InstagramFeed />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <BookingWidget />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <ContactFormWidget />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <LatestReleaseWidget />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <CountdownWidget />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <DonationWidget />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <InsightCarousel />
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <AppInstallWidget />
          </div>
          {/* ImageCardWidget Example */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <ImageCardWidget
              imageUrl="https://cdn.midjourney.com/a9eb779c-1ae3-431d-8079-3c3b0d2b4255/0_1.png"
              title="Travel with Confidence"
              description="Sleek, durable, and ready for your next business trip, this luggage is built for the professional on the go."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Showcase;
