import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDonate } from "@fortawesome/free-solid-svg-icons";
import mobilePayButton from "../media/mobilepay_pay.png"; // Import the MobilePay button image

const DonationWidget = () => {
  const [selectedAmount, setSelectedAmount] = useState(15); // Default selected amount

  const donationAmounts = [2, 15, 25]; // Available donation amounts

  return (
    <div className="p-4 bg-gray-50 rounded-lg shadow-md w-64 mx-auto flex flex-col justify-between min-h-[250px]">
      {/* Widget Title with FontAwesome Icon */}
      <h3 className="text-lg font-semibold mb-1 text-gray-800">
        <FontAwesomeIcon icon={faDonate} className="mr-2" />
        Donation Widget
      </h3>

      {/* Short Description */}
      <p className="text-sm text-gray-600 my-4">
      With this widget, donating is as simple as two clicks.
      </p>

      {/* Call to Action Button */}
      <div className="bg-white border border-gray-200 rounded-lg shadow-inner relative p-4">
        <p className="text-sm text-gray-600 font-semibold mb-4">Select an amount to donate:</p>
        {/* Donation Amount Selection */}
        <div className="mb-4 flex justify-around">
          {donationAmounts.map((amount) => (
      <button
      key={amount}
      className={`py-2 px-4 rounded-md text-xs ${
        selectedAmount === amount
          ? "bg-[#5a78ff] text-white font-bold"
          : "bg-gray-200 text-gray-700"
      } hover:bg-[#5a78ff] hover:text-white transition`}
      onClick={() => setSelectedAmount(amount)}
    >
      {amount}€
    </button>
          ))}
        </div>

        {/* MobilePay Button */}
        <div className="text-center mt-4">
          <a href="#" target="_blank" rel="noopener noreferrer">
            <img
              src={mobilePayButton}
              alt="Donate with MobilePay"
              className="w-full hover:opacity-90 transition"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default DonationWidget;
