import React from "react";
import ComparisonCard from "./ComparisonCard";

const ComparisonSection = () => {
  return (
    <section className="flex justify-center transition-opacity duration-700">
      <div className="h-auto flex items-center justify-center p-4 py-12 md:py-24 lg:py-32">


        <div className="w-full max-w-4xl">
          <h1 className="text-3xl font-bold text-gray-50 text-center mb-6">
          Making Advanced Testing Accessible
          </h1>
          <p className="px-8 text-md text-center text-gray-200 mb-8">
            Our platform not only reports, it automatically adapts and delivers results.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <ComparisonCard
              title="Traditional Testing is"
              description="Difficult"
              items={[
                "Complex setup",
                "Requires technical expertise",
                "Requires manual monitoring",
                "Takes long time to see results",
              ]}
              isPositive={false}
            />
            <ComparisonCard
              title="CTAForm makes testing"
              description="Accessible"
              items={[
                "Plug and play setup",
                "Automatic optimization",
                "Instant insights and analytics",
                "Multivariate testing capabilities",
                "No need to monitor or adjust manually",
                "Get results today",
                "No need to monitor, we'll come back with results!"
              ]}
              isPositive={true}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComparisonSection;
