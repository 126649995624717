import React, { useEffect, useState } from 'react';
import { generateClient } from "aws-amplify/api";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown, faTrash } from "@fortawesome/free-solid-svg-icons";

const client = generateClient();

const VariantEditor = ({ linkId }) => {
  const [variants, setVariants] = useState([]);
  const [newVariant, setNewVariant] = useState({ title: '', url: '', imageUrl: '', description: '' });
  const [showAddVariant, setShowAddVariant] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    fetchVariants();
  }, []);

  const fetchVariants = async () => {
    try {
      const variantData = await client.graphql({
        query: queries.listLinkVariants,
        variables: { filter: { linkID: { eq: linkId } } },
      });
      const fetchedVariants = variantData.data.listLinkVariants.items || [];
      setVariants(fetchedVariants);
    } catch (error) {
      console.error("Error fetching variants:", error);
    }
  };

  const handleNewVariantChange = (key, value) => {
    setNewVariant({ ...newVariant, [key]: value });
  };

  const addVariant = async () => {
    if (!newVariant.title || !newVariant.url) {
      alert("Title and URL are required for a new variant.");
      return;
    }

    try {
      const input = {
        linkID: linkId,
        title: newVariant.title,
        url: newVariant.url,
        imageUrl: newVariant.imageUrl,
        description: newVariant.description,
        index: variants.length + 1,
        views: 0, // Set default views to 0
        clicks: 0, // Set default clicks to 0
      };

      const result = await client.graphql({
        query: mutations.createLinkVariant,
        variables: { input },
      });

      await client.graphql({
        query: mutations.updateLink,
        variables: { input: { id: linkId, hasVariants: true } },
      });

      setVariants([...variants, result.data.createLinkVariant]);
      setNewVariant({ title: '', url: '', imageUrl: '', description: '' });
      setShowAddVariant(false);
    } catch (error) {
      console.error("Error adding new variant:", error);
    }
  };

  const saveVariants = async () => {
    try {
      for (let i = 0; i < variants.length; i++) {
        const variant = variants[i];
        const input = {
          id: variant.id,
          linkID: linkId,
          title: variant.title,
          url: variant.url,
          imageUrl: variant.imageUrl,
          description: variant.description,
          index: variant.index,
        };

        await client.graphql({
          query: mutations.updateLinkVariant,
          variables: { input },
        });
      }
      alert("Variants saved successfully!");
    } catch (error) {
      console.error("Error saving variants:", error);
      alert("Error saving variants. Please try again.");
    }
  };

  // Function to remove a variant
  const removeVariant = async (variantId, index) => {
    if (window.confirm("Are you sure you want to delete this variant?")) {
      try {
        await client.graphql({
          query: mutations.deleteLinkVariant,
          variables: { input: { id: variantId } },
        });

        // Update local state by removing the deleted variant
        const updatedVariants = variants.filter((_, i) => i !== index);
        setVariants(updatedVariants);

        // Check if there are no variants left and update `hasVariants`
        if (updatedVariants.length === 0) {
          await client.graphql({
            query: mutations.updateLink,
            variables: { input: { id: linkId, hasVariants: false } },
          });
        }
      } catch (error) {
        console.error("Error deleting variant:", error);
        alert("Error deleting variant. Please try again.");
      }
    }
  };

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="mt-4">
      <p className="text-sm text-gray-600 mb-2">Link Variants:</p>
      
      {variants.map((variant, index) => (
        <div key={index} className="mb-4 border rounded">
          <div
            className="p-2 bg-gray-100 cursor-pointer flex justify-between items-center"
            onClick={() => toggleExpand(index)}
          >
            <span>{variant.title || `Variant ${index + 1}`}</span>
            <div>
              <button onClick={() => removeVariant(variant.id, index)} className="text-red-600 mr-2">
                <FontAwesomeIcon icon={faTrash} />
              </button>
              <FontAwesomeIcon icon={expandedIndex === index ? faChevronUp : faChevronDown} />
            </div>
          </div>

          {expandedIndex === index && (
            <div className="p-4">
              <input
                type="text"
                value={variant.title || ''}
                onChange={(e) => {
                  const updatedVariants = [...variants];
                  updatedVariants[index].title = e.target.value;
                  setVariants(updatedVariants);
                }}
                placeholder={`Variant ${index + 1} Title`}
                className="w-full mb-2 p-2 border border-gray-300 rounded"
              />
              <input
                type="text"
                value={variant.url || ''}
                onChange={(e) => {
                  const updatedVariants = [...variants];
                  updatedVariants[index].url = e.target.value;
                  setVariants(updatedVariants);
                }}
                placeholder={`Variant ${index + 1} URL`}
                className="w-full mb-2 p-2 border border-gray-300 rounded"
              />
              <input
                type="text"
                value={variant.imageUrl || ''}
                onChange={(e) => {
                  const updatedVariants = [...variants];
                  updatedVariants[index].imageUrl = e.target.value;
                  setVariants(updatedVariants);
                }}
                placeholder={`Variant ${index + 1} Image URL`}
                className="w-full mb-2 p-2 border border-gray-300 rounded"
              />
              <textarea
                value={variant.description || ''}
                onChange={(e) => {
                  const updatedVariants = [...variants];
                  updatedVariants[index].description = e.target.value;
                  setVariants(updatedVariants);
                }}
                placeholder={`Variant ${index + 1} Description`}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          )}
        </div>
      ))}

      <button onClick={saveVariants} className="mt-2 bg-blue-500 text-white p-2 rounded">
        Save Variants
      </button>

      {showAddVariant ? (
        <div className="mt-4">
          <input
            type="text"
            value={newVariant.title}
            onChange={(e) => handleNewVariantChange('title', e.target.value)}
            placeholder="New Variant Title"
            className="w-full mb-2 p-2 border border-gray-300 rounded"
          />
          <input
            type="text"
            value={newVariant.url}
            onChange={(e) => handleNewVariantChange('url', e.target.value)}
            placeholder="New Variant URL"
            className="w-full mb-2 p-2 border border-gray-300 rounded"
          />
          <input
            type="text"
            value={newVariant.imageUrl}
            onChange={(e) => handleNewVariantChange('imageUrl', e.target.value)}
            placeholder="New Variant Image URL"
            className="w-full mb-2 p-2 border border-gray-300 rounded"
          />
          <textarea
            value={newVariant.description}
            onChange={(e) => handleNewVariantChange('description', e.target.value)}
            placeholder="New Variant Description"
            className="w-full p-2 border border-gray-300 rounded"
          />
          <button onClick={addVariant} className="mt-2 bg-green-500 text-white p-2 rounded">
            Add Variant
          </button>
        </div>
      ) : (
        <button onClick={() => setShowAddVariant(true)} className="mt-4 bg-gray-500 text-white p-2 rounded">
          Add New Variant
        </button>
      )}
    </div>
  );
};

export default VariantEditor;
