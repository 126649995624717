import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { GAProvider, useGA } from './GAContext';
import ReactGA from 'react-ga';
import Landing from './pages/Landing';
import Showcase from './pages/Showcase';
import Demo from './pages/Demo';
import Profile from './pages/Profile';
import Test from './pages/Test';
import Test2 from './pages/Test2';
import Flaco from './pages/Flaco';
import ExampleLinkSite from './pages/ExampleLinkSite';
import Dashboard from './pages/Dashboard';
import './index.css';
import { Amplify } from 'aws-amplify';
import publicConfig from './amplifyconfiguration_public.json';
import amplifyconfig from './amplifyconfiguration.json';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import CookieConsentBanner from './components/core/CookieConsentBanner';

// Default Amplify configuration for authenticated routes
Amplify.configure(publicConfig);

const App = () => {
  const location = useLocation();
  const { isGAInitialized } = useGA();

  useEffect(() => {
    if (isGAInitialized) {
      const path = location.pathname + location.search;
      console.log("Tracking page view for path:", path);
      ReactGA.pageview(path);
    }
  }, [location, isGAInitialized]);

  return (
    <>
      {/* <CookieConsentBanner /> */}

      <Routes>

        <Route path="/" element={<Landing />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/donate" element={<Test />} />
        <Route path="/showcase" element={<Showcase />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/taydellinen" element={<Flaco />} />
        <Route path="/test2" element={<Test2 />} />
        {/* Public Route for ExampleLinkSite */}
        <Route
          path="/:name?"
          element={
            <ExampleLinkSite />
          }
        />

        {/* Profile Route with Authenticator */}
        <Route
          path="/profile"
          element={
            <Authenticator>
              {() => {
                Amplify.configure(amplifyconfig);
                return <Profile />;
              }}
            </Authenticator>
          }
        />
      </Routes>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GAProvider>
    <Router>
      <App />
    </Router>
  </GAProvider>
);
